import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  NgZone,
} from "@angular/core";
import { AddressOrder } from "../../models/address-order";
import { DataService } from "src/app/helpers/data.service";
import { Order } from "../../models/order";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { environment } from "../../../environments/environment";
import { MatDialog, MatSnackBar } from "@angular/material";
import { AddressesOrderStatus } from "../../utils/addresses-order-status";
import { User } from "src/app/models/user";
import { AddressesOrderService } from "src/app/services/addressesOrder.service";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-address-order",
  templateUrl: "./address-order.component.html",
  styleUrls: ["./address-order.component.css"],
})
export class AddressOrderComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;

  addressOrder: AddressOrder;
  user: User;
  orderMinDate: Date = new Date();
  defaultDate: FormControl;
  order: Order = {};
  removedAddresses: number = 0;

  @ViewChild("modalInfo", { static: false }) modalInfo: TemplateRef<any>;
  @ViewChild("addressModal", { static: false }) addressModal: TemplateRef<any>;

  constructor(
    private data: DataService,
    private dialog: MatDialog,
    private addressesOrderService: AddressesOrderService,
    private router: Router,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    public translate: TranslateService,
    private ngZone: NgZone
  ) {
    this.orderMinDate.setDate(this.orderMinDate.getDate() + 22);
    this.defaultDate = new FormControl(this.orderMinDate);

    this.order.date = this.defaultDate.value;
  }

  ngOnInit() {
    this.addressOrder = this.data.state.addressOrder;
    this.user = this.data.state.user;
    this.computePrice();

    this.initConfig();
  }

  computePrice() {
    if (this.addressOrder.removed_addresses) {
      this.removedAddresses = this.addressOrder.removed_addresses.length;
    }

    this.order.HTPrice = 0;
    if (this.addressOrder.price) {
      this.order.HTPrice = this.addressOrder.price;
    }
    const VATRate = this.data.state.user.countries.find(
      (country) => country.name === "France"
    ).tva;
    this.order.taxes = this.order.HTPrice * VATRate;

    this.order.totalPrice = this.order.HTPrice + this.order.taxes;
    this.addressOrder.order = this.order;

    this.data.setAddressOrder(this.addressOrder);
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: "EUR",
      clientId: environment.paypal_client_id,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "EUR",
                value: this.order.totalPrice.toFixed(2),
              },
            },
          ],
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        layout: "vertical",
      },
      onApprove: (data) => null,
      onClientAuthorization: (data) => {
        if (data.status === "COMPLETED") {
          return this.handleOrder();
        } else {
          this.snackbar.open(
            this.translate.instant("elm.modal.paymentErrorMessage"),
            this.translate.instant("global.close"),
            { duration: 10000 }
          );
        }
      },
      onError: (err) => {
        this.data.setAddressOrder(null);
        this.authService.logout();
        this.snackbar.open(
          this.translate.instant("global.error-message"),
          this.translate.instant("global.close"),
          { duration: 4000 }
        );
        this.router.navigate(["/"]);
      },
    };
  }

  openDialog() {
    this.ngZone.run(() => {
      this.dialog
        .open(this.modalInfo, { disableClose: true })
        .afterClosed()
        .subscribe(() => {
          this.data.setAddressOrder(null);
          this.router.navigate(["/"], { replaceUrl: true });
        });
    });
  }

  async handleOrder() {
    this.data.enableLoader(true);
    this.addressOrder.status = AddressesOrderStatus.ordered;
    if (this.user.subscription && this.user.type === "Affiliate") {
      this.addressOrder.status = AddressesOrderStatus.waitingForHOConfirmation;
    } else if (!this.user.subscription && this.user.makePayment) {
      this.addressOrder.status = AddressesOrderStatus.payed;
    }
    this.data.setAddressOrder(this.addressOrder);
    try {
      await this.addressesOrderService.update(this.addressOrder).toPromise();
      if (this.order.offeredAddresses && this.order.offeredAddresses > 0) {
        this.user.reduction -= this.addressOrder.order.offeredAddresses;
        this.data.setUser(this.user);
      }
      this.openDialog();
    } catch (err) {
      console.error(err);
    }
    this.data.enableLoader(false);
  }

  removeAddresses() {
    const queryParams = {
      target: "address-order",
    };
    this.router.navigate(["/remove-addresses"], { queryParams });
  }
}
