import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DataService } from '../helpers/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string = null;

  constructor(private http: HttpClient, private dataService: DataService) {
    this.token = localStorage.getItem('token');
  }

  getToken = (): string => {
    return this.token;
  }

  login(username: string, password: string): Observable<User>{
    return this.http.post(`${environment.baseUrl}/authenticate`, {username, password})
      .pipe(
        map((user: User)  => {
          localStorage.setItem('token', user.token);
          this.token = user.token;
          return user;
        }
      ))
  }

  getUserData() {
    return this.http.get<User>(`${environment.baseUrl}/user/data`);
  }

  logout() {
    localStorage.removeItem('token');
    this.token = null;
    this.dataService.clearState();
  }

}
