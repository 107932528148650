import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { AuthGuardService } from "./helpers/auth-guard.service";
import { LoginComponent } from "./pages/login/login.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { AffiliateComponent } from "./pages/affiliate/affiliate.component";
import { ProspectionComponent } from "./pages/prospection/prospection.component";
import { FlyerComponent } from "./pages/flyer/flyer.component";
import { GeoStrategyComponent } from "./pages/geo-strategy/geo-strategy.component";
import { ELMComponent } from "./pages/elm/elm.component";
import { RecapitulateComponent } from "./pages/recapitulate/recapitulate.component";
import { AddressOrderComponent } from "./pages/address-order/address-order.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { UserAccessService } from "./helpers/user-access.service";
import { AffiliateFormComponent } from "./pages/affiliate/affiliate-form/affiliate-form.component";
import { CampaignListComponent } from "./pages/campaign-list/campaign-list.component";
import { AddressOrderListComponent } from "./pages/address-order-list/address-order-list.component";
import { HeadofficeAdministrationComponent } from "./pages/headoffice-administration/headoffice-administration.component";
import { RemoveAddressesComponent } from "./pages/remove-addresses/remove-addresses.component";
import { UserAccessDataVisService } from "./helpers/user-access-data-vis.service";
import { UserAccessElmService } from "./helpers/user-access-elm.service";

const APP_ROUTES: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "home.title" },
  },
  { path: "login", component: LoginComponent },
  {
    path: "contact",
    component: ContactComponent,
    data: { breadcrumb: "home.contact" },
  },
  {
    path: "prospection",
    component: ProspectionComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "home.prospect", allowRedirect: true },
  },
  {
    path: "geo-strategy",
    component: GeoStrategyComponent,
    canActivate: [AuthGuardService, UserAccessDataVisService],
    data: { breadcrumb: "home.geo-strat", allowRedirect: true },
  },
  {
    path: "elm",
    component: ELMComponent,
    canActivate: [AuthGuardService, UserAccessElmService],
    data: { breadcrumb: "home.elm", allowRedirect: true },
  },
  {
    path: "flyer",
    component: FlyerComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "flyer-step.breadcrumb" },
  },
  {
    path: "recapitulate",
    component: RecapitulateComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "recapitulate.summary" },
  },
  {
    path: "order-addresses",
    component: AddressOrderComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "address-order.summary" },
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuardService],
    data: {
      breadcrumb: "change-password.change-password",
      allowRedirect: true,
    },
  },
  {
    path: "affiliate",
    component: AffiliateComponent,
    data: { breadcrumb: "home.manage-affiliate", allowRedirect: true },
    canActivate: [AuthGuardService, UserAccessService],
  },
  {
    path: "affiliate/create",
    component: AffiliateFormComponent,
    data: { breadcrumb: "affiliate.title.create", allowRedirect: true },
    canActivate: [AuthGuardService, UserAccessService],
  },
  {
    path: "affiliate/update",
    component: AffiliateFormComponent,
    data: { breadcrumb: "affiliate.title.update" },
    canActivate: [AuthGuardService, UserAccessService],
  },
  {
    path: "administration",
    component: HeadofficeAdministrationComponent,
    canActivate: [AuthGuardService, UserAccessService],
    data: {
      breadcrumb: "headoffice-administration.headoffice-administration",
      allowRedirect: true,
    },
  },
  {
    path: "campaigns",
    component: CampaignListComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "campaign-list.campaign-list", allowRedirect: true },
  },
  {
    path: "address-orders",
    component: AddressOrderListComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "address-order-list.summary", allowRedirect: true },
  },
  {
    path: "remove-addresses",
    component: RemoveAddressesComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: "remove-addresses.remove-addresses" },
  },

  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

export const appRoutingModule = RouterModule.forRoot(APP_ROUTES);
