import { Validators, FormGroup } from '@angular/forms';


export const passwordValidator = [
    Validators.required,
    Validators.minLength(8)
]

export const phoneValidator = Validators.pattern(/^((\+)33|0|0033)[1-9](\d{2}){4}$/);

export function confirmPasswordValidator(group: FormGroup){
    const password = group.controls.password.value;
    const passwordConfirm = group.controls.confirmPassword.value;

    return password === passwordConfirm ? null : { passwordNotEqual: true }
}

export const postCodeValidator = Validators.pattern(/^[0-9]{5}$/);
