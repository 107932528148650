const dimensionsFromWidth = (width, ratio) => ({
  w: width,
  h: width / ratio,
});

const addSlide = (elmGenerator) => {
  const { elmData } = elmGenerator;

  // first slide: map
  let slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/final.jpg",
    w: 10,
    h: 3.88,
    x: 0,
    y: 1.74,
  });

  slide.addImage({
    path: "assets/images/elm/logo_pixstart.png",
    x: 3.32,
    y: 0.08,
    w: 2.93,
    h: 1.26,
  });

  slide.addText("From space data to strategic decisions", {
    w: "100%",
    h: 1.74,
    fontFace: "Calibri",
    fontSize: 15,
    color: "008FC9",
    align: "center",
    valign: "bottom",
  });

  slide.addText(
    [
      { text: "Contact", options: { breakLine: true } },
      { text: "-", options: { breakLine: true } },
      { text: "Richard Barré", options: { breakLine: true } },
      {
        text: "richard.barre@pixstart.io",
        options: {
          breakLine: true,
          hyperlink: { url: "mailto:richard.barre@pixstart.io" },
          color: "FFFFFF",
        },
      },
      { text: "+33 671131736", options: { breakLine: true } },
      { text: "", options: { breakLine: true } },
      { text: "", options: { breakLine: true } },
    ],
    {
      w: 3.25,
      h: 3.34,
      x: 3.38,
      y: 2.09,
      fontFace: "Calibri",
      fontSize: 18,
      color: "D8D8D8",
      align: "center",
      valign: "center",
      shape: elmGenerator.pres.shapes.ROUNDED_RECTANGLE,
      rectRadius: 0.2,
      fill: { color: "484F56", transparency: 20 },
    }
  );

  const currentYear = new Date().getFullYear();

  slide.addText(`© Pixstart. ${currentYear}`, {
    color: "008FC9",
    fontSize: 9,
    fontFace: "Calibri",
    w: 1.03,
    h: 0.26,
    x: 8.97,
    y: 5.36,
  });
};

export default addSlide;
