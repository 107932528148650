<h2 mat-dialog-title>{{ "address-order.summary" | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <p>{{ "address-order.info.content" | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">
    {{ "global.cancel" | translate }}
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    {{ "address-order.info.action" | translate }}
  </button>
</mat-dialog-actions>
