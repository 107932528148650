const dimensionsFromWidth = (width, ratio) => ({
  w: width,
  h: width / ratio,
});

const addSlide = (elmGenerator, stat) => {
  const { elmData } = elmGenerator;

  // first slide: map
  let slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover3.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if(elmGenerator.logo){
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61
      }
    })
  }

  slide.addText(stat.title, {
    w: "100%",
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "484F56",
    align: "center",
  });

  const { legend, legendDimensions, map } = elmData[stat.property];

  const legendRatio = legendDimensions.width / legendDimensions.height;

  slide.addImage({
    data: legend,
    ...dimensionsFromWidth(1.5, legendRatio),
    x: 2.6,
    y: 1.56,
    sizing: {
      type: "contain",
      w: 1.5,
      h: 1.5,
    },
  });

  slide.addImage({
    data: map,
    ...dimensionsFromWidth(5.3, 4 / 3),
    x: 4.18,
    y: 0.8,
  });

  if (stat.disableDataSlide) return;
  // second slide: table
  slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover5.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if(elmGenerator.logo){
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61
      }
    })
  }

  slide.addText(
    [
      {
        text: stat.title,
        options: { breakLine: true },
      },
      {
        text: "- Comparatif -",
        options: { color: "9BCF63" },
      },
    ],
    {
      align: "center",
      valign: "center",
      color: "484F56",
      fontFace: "Dosis",
      fontSize: 36,
      w: 4.78,
      h: 1.38,
      x: 4.72,
      y: 0.35,
    }
  );

  const headerOptions = {
    fontSize: 14,
    color: "51B148",
  };

  const slimBorder = { pt: 1, type: "solid", color: "9BCF63" };
  const thickBorder = { pt: 2, type: "solid", color: "9BCF63" };

  const rows = [
    [
      {
        text: "",
        options: {
          ...headerOptions,
          rowspan: stat.showNationalAvg ? 1 : 2,
          border: [thickBorder, slimBorder, slimBorder, null],
        },
      },
      ...(stat.showSum
        ? [
            {
              text: "Total",
              options: {
                ...headerOptions,
                rowspan: stat.showNationalAvg ? 1 : 2,
                border: [thickBorder, slimBorder, slimBorder, slimBorder],
              },
            },
          ]
        : []),
      {
        text: "Moyenne / Iris",
        options: {
          ...headerOptions,
          rowspan: stat.showNationalAvg ? 1 : 2,
          border: [thickBorder, slimBorder, slimBorder, slimBorder],
        },
      },
      {
        text: "Minimum / Iris",
        options: {
          ...headerOptions,
          rowspan: 2,
          border: [thickBorder, slimBorder, slimBorder, slimBorder],
        },
      },
      {
        text: "Maximum / Iris",
        options: {
          ...headerOptions,
          rowspan: 2,
          border: [thickBorder, slimBorder, slimBorder, slimBorder],
        },
      },
    ],
    stat.showNationalAvg
      ? [
          {
            text: "National",
            options: {
              ...headerOptions,
              align: "left",
              fill: { color: "EEF1F3" },
              border: [slimBorder, slimBorder, slimBorder, null],
            },
          },
          {
            text: stat.transform(elmData[stat.property].data.nationalAvg),
            options: {
              fill: { color: "EEF1F3" },
              border: [slimBorder, thickBorder, slimBorder, slimBorder],
            },
          },
        ]
      : [],
    [
      {
        text: "Sur la zone",
        options: {
          ...headerOptions,
          align: "left",
          border: [slimBorder, slimBorder, slimBorder, null],
        },
      },
      ...(stat.showSum
        ? [stat.transform(elmData[stat.property].data.zoneSum)]
        : []),
      stat.transform(elmData[stat.property].data.zoneAvg),
      stat.transform(elmData[stat.property].data.zoneMin),
      stat.transform(elmData[stat.property].data.zoneMax),
    ],
  ];

  slide.addTable(rows, {
    w: 5.35,
    h: 2.27,
    x: 4.15,
    y: 2.2,
    fontSize: 18,
    color: "484F56",
    border: slimBorder,
    valign: "center",
    align: "center",
  });
};

export default addSlide;
