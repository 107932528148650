import { Injectable } from '@angular/core';
import {Store} from 'rxjs-observable-store';
import { Campaign } from '../models/campaign';
import { User } from '../models/user';
import { AddressOrder } from '../models/address-order';


class DataState {
  loader?: boolean;
  campaign?: Campaign;
  addressOrder?: AddressOrder;
  user?: User;
  affiliateToUpdate?: any
}


/**
 * This class allow to have a shared service with the current state of the application
 * For more information about the package used please refer to https://github.com/jurebajt/rxjs-observable-store
 */
@Injectable({
  providedIn: 'root'
})
export class DataService extends Store<DataState>{

  constructor(){
    super(new DataState());
  }

  setUser(user: User){
    this.setState({
      ...this.state,
      user: user
    })
  }

  enableLoader(loader: boolean){
    this.setState({
      ...this.state,
      loader: loader
    })
  }

  setCampaign(campaign: Campaign){
    this.setState({
      ...this.state,
      campaign: campaign
    })
  }

  setAddressOrder(addressOrder: AddressOrder){
    this.setState({
      ...this.state,
      addressOrder
    })
  }

  setAffiliateToUpdate(affiliate){
    this.setState({
      ...this.state,
      affiliateToUpdate: affiliate
    })
  }

  clearState(){
    this.setState(new DataState());
  }
}
