const dimensionsFromWidth = (width, ratio) => ({
  w: width,
  h: width / ratio,
});

const addSlide = (elmGenerator) => {
  const { elmData, dep } = elmGenerator;

  // first slide: map
  let slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover3.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if (elmGenerator.logo) {
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61,
      },
    });
  }

  let title = "Localisation de la concurrence";
  if (dep) {
    title = `Localisation de la concurrence - ${dep}`;
  }
  slide.addText(title, {
    w: "100%",
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "484F56",
    align: "center",
  });

  const { map, data } = elmData.competition;

  const { legend, legendDimensions } = elmData["nb_pools"];

  const legendRatio = legendDimensions.width / legendDimensions.height;

  slide.addImage({
    data: legend,
    ...dimensionsFromWidth(1.5, legendRatio),
    x: 2.6,
    y: 1.56,
    sizing: {
      type: "contain",
      w: 1.5,
      h: 1.5,
    },
  });

  slide.addImage({
    data: map,
    ...dimensionsFromWidth(5.3, 4 / 3),
    x: 4.18,
    y: 0.8,
  });

  // second slide: table
  slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addText(`Liste des concurrents - Sur la zone`, {
    w: "100%",
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "484F56",
    align: "center",
  });

  const rows = [
    ["Nom", "Adresses", "Site web"].map((text) => ({
      text,
      options: {
        color: "000000",
        fill: { color: "9BCF63" },
        bold: true,
      },
    })),
    ...data.map((shop, index) => {
      const options = {
        fill: {
          color: index % 2 === 1 ? "e1f1d0" : "ffffff",
        },
      };
      return [
        { text: shop.name, options },
        { text: shop.address || "", options },
        {
          text: shop.website || "",
          options: shop.website
            ? {
                ...options,
                hyperlink: { url: shop.website },
                color: "4264fb",
              }
            : options,
        },
      ];
    }),
  ];

  slide.addTable(rows, {
    w: 9.67,
    h: 3.03,
    x: 0.17,
    y: 1.1,
    fontSize: 10,
    color: "484F56",
    border: { pt: 1, type: "solid", color: "e3e4e4" },
    valign: "center",
    align: "left",
    autoPage: true,
    autoPageLineWeight: 0.3,
  });
};

export default addSlide;
