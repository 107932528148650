import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-address-order-info-dialog",
  templateUrl: "./address-order-info-dialog.component.html",
  styleUrls: ["./address-order-info-dialog.component.css"],
})
export class AddressOrderInfoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddressOrderInfoDialogComponent>
  ) {}

  ngOnInit() {}
}
