<div class="section"></div>
<div class="section" *ngIf='campaign.postage'>
    <div class="container">
        <div class="row">
            <div class="col s12">
                <h4 class='page-title'>{{'flyer-step.title' | translate}} </h4>
                <mat-form-field class='center-align col select'>
                    <mat-select class='postage-select' [(ngModel)]='campaign.postage.selectedPostageType' >
                        <mat-option  *ngFor="let postage of postageTypes" [value]='postage'>
                            {{postage.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                    {{showPrice()}}
            </div>
        </div>
        <div class="row">
            <div class="col m6">
                <div class="file-field input-field">
                    <div class="btn rounded-button">
                        <span>{{'flyer-step.flyer' | translate}}</span>
                        <input type="file" name='flyer' accept="application/pdf" (change)='uploadFile($event)'>
                    </div>
                    <div class="file-path-wrapper">
                        <input class="file-path validate white-text" type="text" >
                    </div>
                </div>
            </div>
            <div class="col m6">
                    <div class="file-field input-field">
                        <div class="btn rounded-button">
                            <span>Icon</span>
                            <input type="file" name='logo' accept="application/pdf, image/*" (change)='uploadFile($event)'>
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path validate white-text" type="text">
                        </div>
                    </div>
            </div>
        </div>

        <div class="row">
            <div class="col m6 center-align row">
                <div *ngIf='flyer.progress' class="col s12">
                    <div class="progress-container">
                        <div class="progress-bar" [style.width]='flyer.progress * 100+"%"'>{{flyer.progress *100 | number: '2.0-0' }} %</div>
                    </div>
                    <br>
                </div>
                <pdf-viewer *ngIf='flyer.file !== null else wrongFile' 
                class='file-viewer'
                [original-size]="false" 
                (after-load-complete)="afterLoadComplete($event, 'flyer')"
                [src]="flyer.url"
                [show-all]="false"
                (click)='showFile(flyer)'
                ></pdf-viewer>
                
            </div>
            <div class="col m6 center-align row">
                <div *ngIf='logo.progress' class="col s12">
                    <div class="progress-container">
                        <div class="progress-bar" [style.width]='logo.progress * 100+"%"'>{{logo.progress * 100 | number: '2.0-0'}} %</div>
                    </div>
                    <br>
                </div>
                <div *ngIf='Object.entries(logo).length > 0'>
                    <div *ngIf='logo.file && (logo.file.type === "application/pdf" || logo.file.type.split("/")[0] === "image") else wrongFile'>
                        <pdf-viewer *ngIf='logo.file.type === "application/pdf"' 
                        class='file-viewer'
                        [original-size]="false" 
                        (after-load-complete)="afterLoadComplete($event, 'logo')"
                        [src]="logo.url"
                        (click)='showFile(logo)'
                        ></pdf-viewer>

                        <img *ngIf='logo.file.type.split("/")[0] === "image"' class='responsive-img' (click)='showFile(logo)' width="200" [src]="logo.url">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
                <div class="col s12 right-align">
                    <button class="btn rounded-button" [disabled]='(!flyer.file || !logo.file)' (click)='upload()'>{{'global.submit' | translate}}</button>
                </div>
        </div>
    </div>
</div>





<ng-template #wrongFile>
    <p>{{'flyer-step.wrong-file' | translate}}</p>
</ng-template>