import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatSnackBar, MatDialogRef } from "@angular/material";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import download from "downloadjs";

import { DataService } from "src/app/helpers/data.service";
import { ElmService } from "src/app/services/elm.service";

import { environment } from "../../../environments/environment";
import depData, { Departement } from "./dep-data";
import { User } from "../../models/user";
import { Elm } from "../../models/elm";
import ELMGenerator from "./generate-elm";

type ElmType = "DEP" | "IRISES" | "ZIPCODES";

const IRIS_REGEX = new RegExp(/\d{9}/);
const ZIPCODE_REGEX = new RegExp(/\d{5}|2[AB]\d{3}/);

const fileToText = async (file) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => resolve(e.target.result);
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

@Component({
  selector: "app-elm",
  templateUrl: "./elm.component.html",
  styleUrls: ["./elm.component.css"],
})
export class ELMComponent implements OnInit {
  depData: Departement[] = depData;
  public payPalConfig?: IPayPalConfig;
  selectedDepCode: string;
  selectedZipCodes: string[];
  selectedIrises: string[];
  zipCodeFileName: string;
  irisFileName: string;
  pricing: any;

  currentUser: User;
  allElms: Elm[] = [];
  elm: Elm;
  orderDialogRef: MatDialogRef<any>;
  confirmationDialogRef: MatDialogRef<any>;
  loadingMessage: string;
  errorMessage: string;

  loading: boolean = false;
  @ViewChild("orderModal", { static: false }) orderModal: TemplateRef<any>;
  @ViewChild("confirmationModal", { static: false }) confirmationModal: TemplateRef<any>;

  constructor(
    private dataService: DataService,
    private elmService: ElmService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentUser = this.dataService.state.user;
    this.pricing = this.currentUser.elmPricing;
    if (this.currentUser.headOffice) {
      this.pricing = this.currentUser.headOffice.elmPricing;
    }
    this.elmService.setS3config(this.currentUser.s3config);

    this.elmService.getAll().subscribe((elms) => (this.allElms = elms));
  }

  _getPrice(elmType: ElmType) {
    const user = this.currentUser;
    switch (elmType) {
      case "DEP":
        return this.pricing.price_dep * 1.2;
      case "IRISES":
        return this.selectedIrises.length * this.pricing.price_iris * 1.2;
      case "ZIPCODES":
        return this.selectedZipCodes.length * this.pricing.price_zipcode * 1.2;
    }
  }

  async onIrisesFileLoad(e) {
    const { files } = e.target;
    if (!files[0]) return null;
    const txt: any = await fileToText(files[0]);
    if (!txt) return;
    const irises = txt.split(/\r?\n/).filter((_) => _ !== "");
    for (const iris of irises) {
      if (!iris.match(IRIS_REGEX)) {
        console.error(`Invalid IRIS: ${iris}`);
        this.errorMessage = this.translate.instant("elm.errors.parsing");
        return;
      }
    }
    this.selectedIrises = irises;
    this.irisFileName = files[0].name;
  }

  async onZipCodesFileLoad(e) {
    const { files } = e.target;
    if (!files[0]) return null;
    const txt: any = await fileToText(files[0]);
    if (!txt) return;
    const zipCodes = txt.split(/\r?\n/).filter((_) => _ !== "");
    for (const zipCode of zipCodes) {
      if (!zipCode.match(ZIPCODE_REGEX)) {
        console.error(`Invalid zipcode: ${zipCode}`);
        this.errorMessage = this.translate.instant("elm.errors.parsing");
        return;
      }
    }
    this.selectedZipCodes = zipCodes;
    this.zipCodeFileName = files[0].name;
  }

  openOrderDialog(elmType: ElmType) {
    const price = {
      currency_code: "EUR",
      value: this._getPrice(elmType).toFixed(2),
    };

    let payPalConfig = null;
    if (this.currentUser.makePayment && price.value !== "0.00") {
      payPalConfig = {
        currency: "EUR",
        clientId: environment.paypal_client_id,
        createOrderOnClient: (data) =>
          <ICreateOrderRequest>{
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: price,
              },
            ],
          },
        advanced: { commit: "true" },
        style: {
          label: "paypal",
          layout: "vertical",
        },
        onApprove: (data) => null,
        onClientAuthorization: (data) => {
          if (data.status === "COMPLETED") {
            return this.onPaymentSuccess(elmType, price, true);
          }
          this.snackbar.open(
            this.translate.instant("elm.modal.paymentErrorMessage"),
            this.translate.instant("global.close"),
            { duration: 10000 }
          );
        },
        onError: (err) => {
          this.snackbar.open(
            this.translate.instant("global.error-message"),
            this.translate.instant("global.close"),
            { duration: 4000 }
          );
        },
      };
    }

    this.orderDialogRef = this.dialog.open(this.orderModal, {
      data: { elmType, payPalConfig, price },
    });
  }

  openConfirmationModal(modalData){
    this.orderDialogRef.close();
    this.confirmationDialogRef = this.dialog.open(this.confirmationModal, {
      data: modalData,
    });
  }

  async onPaymentSuccess(elmType: String, price: Object, paymentMade: boolean) {
    this.loading = true;
    this.loadingMessage = this.translate.instant("elm.loading.ordering");
    let status: any = "ORDERED";
    if (paymentMade) {
      status = "PAYED";
    } else if (this.currentUser.headOffice) {
      status = "WAITING_FOR_HO";
    }
    const elm: Elm = {
      status,
      user: this.currentUser.id,
      price,
      date: new Date(),
    };
    switch (elmType) {
      case "DEP":
        elm.dep = this.selectedDepCode;
      case "IRISES":
        elm.irises = this.selectedIrises;
      case "ZIPCODES":
        elm.zipCodes = this.selectedZipCodes;
    }
    try {
      const { id } = await this.elmService.createElm(elm).toPromise();
      elm.id = id;
    } catch (e) {
      console.error(e);
      this.errorMessage = this.translate.instant("elm.errors.ordering");
      this.loading = false;
      return;
    }
    this.orderDialogRef.close();
    this.confirmationDialogRef.close();
    if (elm.status === "ORDERED" || elm.status === "PAYED") {
      await this.generateElm(elm);
    } else {
      this.allElms = await this.elmService.getAll().toPromise();
      this.loading = false;
    }
  }

  async generateElm(elm: Elm) {
    this.loading = true;
    delete this.errorMessage;
    this.loadingMessage = this.translate.instant("elm.loading.generating");
    const containerElt = document.getElementById("generate-container");
    const generator = new ELMGenerator(containerElt, this.currentUser);
    try {
      const file = await generator.generate(elm);
      await this.elmService.putElmFile(elm, file);
      this.downloadElmFile(elm, file);
    } catch (e) {
      console.error(e);
      this.errorMessage = this.translate.instant("elm.errors.generating");
    }
    this.allElms = await this.elmService.getAll().toPromise();
    this.loading = false;
  }

  async downloadElm(elm: Elm) {
    this.loading = true;
    delete this.errorMessage;
    this.loadingMessage = this.translate.instant("elm.loading.downloading");
    try {
      const file: any = await this.elmService.getElmFile(elm);
      this.downloadElmFile(elm, new Blob([file]));
    } catch (e) {
      console.error(e);
      this.errorMessage = this.translate.instant("elm.errors.downloading");
      this.loading = false;
      return;
    }
    this.loading = false;
  }

  downloadElmFile(elm: Elm, file: any) {
    let subtitle;
    if (elm.dep) {
      subtitle = `département ${elm.dep}`;
    } else if (elm.irises && elm.irises.length) {
      subtitle = `${elm.irises.length} iris`;
    } else {
      subtitle = `${elm.zipCodes.length} communes`;
    }
    download(
      file,
      `ELM Poolspot - ${subtitle}.pptx`,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    );
  }
}
