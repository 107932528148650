import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { DataService } from '../../helpers/data.service';
import { TranslateService } from '@ngx-translate/core';

interface Breadcrumb {
  name: string,
  url: string
}


@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = [];
  currentUser;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    translate: TranslateService
  ) { }

  ngOnInit() {
    this.dataService.state$
      .pipe(map(state => state.user), distinctUntilChanged())
      .subscribe(user => {
        this.currentUser = user
      })
    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        if(this.currentUser){
          let breadcrumb: Breadcrumb = {
            name: event.state.root.firstChild.data['breadcrumb'],
            url: event['url']
          }
          if(!this.breadcrumbs.find(bread => bread.url === breadcrumb.url)) {
            this.breadcrumbs.push(breadcrumb)
          } else {
            let index = this.breadcrumbs.findIndex(bread => bread.url === breadcrumb.url);
            this.breadcrumbs.length = index + 1;
          }
        } else {
          this.breadcrumbs = [];
        }
      }
    });
  }

  clickedRoute(index){
    if(this.breadcrumbs.length - 1 !== index)
      this.breadcrumbs.length = index ;
  }

}
