import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocation } from '../models/geo-location';
import { environment } from '../../environments/environment';
import { Campaign } from '../models/campaign';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  
  constructor(private http: HttpClient) {}

  countByGeoloc(geoloc: GeoLocation, radius: number, userId: string) {
    return this.http.post(`${environment.baseUrl}/campaign/count`, {geoloc: geoloc, radius: radius, userId: userId});
  }

  init(campaign: Campaign){
    return this.http.post(`${environment.baseUrl}/campaign/init`, campaign);
  }

  initByZone(campaign: Campaign){
    return this.http.post(`${environment.baseUrl}/campaign/initByZone`, campaign);
  }

  update(campaign: Campaign){
    return this.http.put(`${environment.baseUrl}/campaign/update`, campaign);
  }

  updateByZone(campaign: Campaign){
    return this.http.put(`${environment.baseUrl}/campaign/updateByZone`, campaign);
  }

  campaignsByUser(userId: string): Observable<Campaign[]>{
    return this.http.get<Campaign[]>(`${environment.baseUrl}/campaign/${userId}`)
  }

  replayCampaign(campaign: Campaign){
    return this.http.post(`${environment.baseUrl}/campaign/replay`, campaign);
  }

  completeCampaign(campaign: Campaign){
    return this.http.post(`${environment.baseUrl}/campaign/complete`, campaign);
  }

  removeAddresses(campaign: Campaign, addresses){
    return this.http.post(`${environment.baseUrl}/campaign/remove-addresses`, {campaign: campaign, addresses: addresses});
  }
}
