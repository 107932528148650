import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AffiliateService } from '../../services/affiliate.service';
import { DataService } from 'src/app/helpers/data.service';
import { first } from 'rxjs/operators';
import { Affiliate } from '../../models/affiliate';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.css']
})
export class AffiliateComponent implements OnInit {

  currentUser: User;
  affiliates: Affiliate[];


  @ViewChild('affiliateStatusModal', {static: false}) affiliateStatusModal: TemplateRef<any>;
  @ViewChild('affiliateReductionModal', {static: false}) affiliateReductionModal: TemplateRef<any>;

  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private affiliateService: AffiliateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.currentUser = this.dataService.state.user;
  }

  ngOnInit() {
    setTimeout(() => this.dataService.enableLoader(true));

    this.affiliateService
      .getAffiliate(this.currentUser.id)
      .pipe(first())
      .subscribe(users => {
        this.affiliates = users;
        this.dataService.enableLoader(false);

        },
        error => {
          this.dataService.enableLoader(false);
          console.error(error)
        }
      )
  }

  navigateTo(path: string, params?){
  this.dataService.setAffiliateToUpdate(params ? params: null);
    this.router.navigate([path]);
  }

/**
 * Disable or enable an affiliate
 * @param affiliate Affiliate
 */
  changeStatus(affiliate: Affiliate){
    affiliate.headOffice = this.currentUser;
    this.affiliateService.changeAffiliateStatus(affiliate)
    .subscribe(
      data =>  {
        if(data){
          this.affiliates.filter(aff => aff.id === affiliate.id)[0].enabled = !affiliate.enabled;
          this.snackBar.open(`${affiliate.username} ${this.translate.instant('affiliate.success.updated')}`, this.translate.instant('global.close'), {
            duration: 2000
          })
        }
      },
      error => console.error(error)
    )
  }


  openChangeStatusDialog(affiliate){
    this.dialog
      .open(this.affiliateStatusModal, {data: affiliate})
      .afterClosed()
      .subscribe(
        status => status ? this.changeStatus(affiliate) : null
      );
  }

  openReductionDialog(affiliate){
    affiliate.headOffice = this.currentUser;
    if(affiliate.reduction) {
      // This fix is needed to handle existing rounding errors
      affiliate.reduction = Math.round(affiliate.reduction * 100) / 100;
    }

    this.dialog
    .open(this.affiliateReductionModal, {data: affiliate})
    .afterClosed()
    .subscribe(
      data => {
        if(data){
          this.affiliateService.editReduction(affiliate)
            .subscribe(
              data => {
                const message = `${affiliate.username} ${this.translate.instant('affiliate.success.updated')}`;
                this.snackBar.open(message, this.translate.instant('global.close'), {
                  duration: 2000
                })
              },
              error => console.error(error)
            )
          ;
        }
      }
    )
  }

  previousCampaigns(affiliate: Affiliate){
    this.router.navigate(['campaigns'], {queryParams: {id: affiliate.id}})
  }

  previousOrders(affiliate: Affiliate){
    this.router.navigate(['address-orders'], {queryParams: {id: affiliate.id}})
  }
}
