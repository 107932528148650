<header>
  <nav>
    <div class="nav-wrapper valign-wrapper row">

      <img src='assets/images/poolspot.png' alt='Poolspot by Pixstart' height="70" [routerLink]="'/'" />

      <breadcrumb></breadcrumb>

      <ul id="nav" class="valign-wrapper align-right-buttons">
        <li><button class="language-button" [class.active]=" translate.currentLang === 'fr'"
            (click)="useLanguage('fr')">FR</button></li>
        <li><button class="language-button" [class.active]=" translate.currentLang === 'en'"
            (click)="useLanguage('en')">EN</button></li>
        <li *ngIf='currentUser' class='valign-wrapper'>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
            <mat-icon>account_circle</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)='navigateTo("change-password")'>
              <span>{{'change-password.change-password' | translate}}</span>
            </button>
            <button mat-menu-item (click)='logout()'>
              <span>Logout</span>
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </nav>

</header>
