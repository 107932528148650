import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pitch',
  templateUrl: './pitch.component.html',
  styleUrls: ['./pitch.component.css']
})
export class PitchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
