import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DataService } from './data.service';
import { User } from '../models/user';



@Injectable({
  providedIn: 'root'
})
export class UserAccessDataVisService  implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    const currentUser: User = this.dataService.state.user;

    if(currentUser.accessDataVis)
      return true;
    this.router.navigate(['/login']);
    return false;
  }
}
