import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // bypass when calling La Poste open data
    if (request.url.includes("datanova")) return next.handle(request);

    if(!environment.hmr){
      request = request.clone({
        setHeaders: {
          'x-api-key': environment.apiKey
        }
      });
    }

    // add authorization header with jwt token if available
    const token = this.authService.getToken();
    if (token) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    return next.handle(request);
}

}
