const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover2.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if(elmGenerator.logo){
    slide.addImage({
      data: elmGenerator.logo,
      x: 8.9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61
      }
    })
  }

  slide.addText("Rappel", {
    x: 4.18,
    y: 0.64,
    w: 5.32,
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "9BCF63",
  });

  slide.addText(
    [
      { text: "Loi Doubin", options: { bold: true, breakLine: true } },
      {
        text: "L’article L330-3 du Code de Commerce dite “loi Doubin” dispose :",
        options: { breakLine: true },
      },
      {
        text: "(complété des articles R.330-1 et suivants du Code de Commerce)",
        options: { breakLine: true },
      },
      {
        text: "Toute personne qui met à la disposition d’une autre personne un nom commercial, une marque ou une enseigne, en exigeant d’elle un engagement d’exclusivité ou de quasi-exclusivité pour l’exercice de son activité, est tenue, préalablement à la signature de tout contrat conclu dans l’intérêt commun des deux parties, de fournir à l’autre partie un document donnant des informations sincères, qui lui permette de s’engager en connaissance de cause.",
        options: { breakLine: true },
      },
      {
        text: "Parmi ces informations, figure une présentation de l’état général et local du marché des produits ou services devant faire l’objet du contrat et des perspectives de développement de ce marché.",
      },
    ],
    {
      x: 4.18,
      y: 1.39,
      w: 5.69,
      h: 4.07,
      fontFace: "Pontano Sans",
      fontSize: 12,
      color: "484F56",
      valign: "top",
      paraSpaceAfter: 6,
    }
  );
};

export default addSlide;
