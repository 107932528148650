    <div class="row">
        <div class="container">
          <h4 class="page-title"> {{"prospection.select-addr" | translate }}</h4>
          <div class="card">
            <div class="card-content black-text">
                <button class="save-button" mat-icon-button (click)="saveAsCampaign()" [disabled]='!campaign.numberOfAddresses'>
                  <mat-icon>save</mat-icon>
                </button>
                <div class="row">
                  <div class="col s12 m6 l5">
                    <div class="input-field">
                      <h6 class='card-title'>{{ 'prospection.center-addr' | translate }}</h6>
                      <Autocomplete [address]='campaign.address.full_address' (setAddress)="setAddress($event)"></Autocomplete>
                    </div>
                    <div class="input-field center-align">
                      <button class="btn blue darken-4" (click)='countAddresses()' [class.disabled]='!enableFindButton'>
                        <i class="material-icons right">search</i>
                        {{ 'global.search' | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="row col s12 m6 l6 offset-l1 input-field">
                    <h6 class='catchment-area card-title'>{{'prospection.catchment-area' | translate }}</h6>
                      <ng5-slider class="col s12 m9" (userChangeEnd)='radiusChange()' [(value)]="campaign.radius" [options]="sliderOptions" ></ng5-slider>
                      <input type="number" class="col s12 m2 offset-m1 radius-nb" [(ngModel)]="campaign.radius" [disabled]='disableRadius' [class.disabled]='disableRadius' max="100" min='1' (change)='radiusChange()' (keyup)='radiusChange()'>
                  </div>
                  <!--*ngIf='campaign.numberOfAddresses'-->
                  <div class="card-action col s12" *ngIf='campaign.numberOfAddresses'>
                    <div class="black-outline card-action-padding">
                      <div class='row'>
                        <p class="col s6 m5 l5" *ngIf='campaign.numberOfAddressesArray | radius: campaign.radius'>
                            {{campaign.numberOfAddressesArray | radius: campaign.radius}} {{'prospection.addr-found' | translate }}
                        </p>
                        <p class="col m3 s6 l4 right-align" *ngIf='campaign.numberOfAddressesArray | radius: campaign.radius'>
                          <span matTooltip="{{'global.vat-exclude' | translate}}" [matTooltipPosition]='"left"'>
                            {{(campaign.numberOfAddressesArray | radius: campaign.radius) * priceToShow | currency: 'EUR'}}*
                          </span>
                        </p>
                        <div class="col m3 l3 s12 right-align">
                          <button class="rounded-button" (click)='orderCampaign()'>{{'prospection.orderCampaign' | translate }}</button>
                        </div>
                      </div>
                      <div *ngIf="currentUser.buyAddresses" class='row'>
                        <p class="col offset-s6 offset-m5 offset-l5 m3 s6 l4 right-align" *ngIf='campaign.numberOfAddressesArray | radius: campaign.radius'>
                          <span matTooltip="{{'global.vat-exclude' | translate}}" [matTooltipPosition]='"left"'>
                            {{getBuyPrice(campaign.numberOfAddressesArray | radius: campaign.radius)   | currency: 'EUR'}}*
                          </span>
                        </p>
                        <div class="col m3 l3 s12 right-align">
                          <button class="rounded-button" (click)='orderAddresses()'>{{'prospection.orderAddresses' | translate }}</button>
                        </div>
                      </div>
                    </div>

                    <blockquote *ngIf='currentUser.irisToEnable.length > 0 || currentUser?.depsToEnable.length > 0'>
                      {{'prospection.in-ur-iris' | translate}}
                    </blockquote>

                </div>
                  <mgl-map
                    [style]="style"
                    [zoom]='10'
                    [maxZoom]="10"
                    [interactive] = 'false'
                    class='col s12'
                    (load)="initMap($event)"
                    *ngIf='campaign.address.coordinates.coordinates'
                    >
                  </mgl-map>

                </div>
            </div>
          </div>
        </div>
      </div>



