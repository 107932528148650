/// <reference types="@types/googlemaps" />
import { NgZone, OnInit } from '@angular/core';


import { Component, ViewChild, EventEmitter, Output, AfterViewInit, Input } from '@angular/core';


@Component({
  selector: 'Autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit, AfterViewInit {

  @Input() adressType: string;
  @Input() address: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext',  {static: false}) addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor(private zone: NgZone) {
  }

  ngOnInit(){
    this.autocompleteInput = this.address;
  }
  

  ngAfterViewInit() {
      this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
      const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
          {
              componentRestrictions: { country: 'FR' },
            //   types: ['establishment']  // 'establishment' / 'address' / 'geocode'
          });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const place = autocomplete.getPlace();
          
          this.zone.run(() => this.invokeEvent(place));
      });
  }

  invokeEvent(place: Object) {
      this.setAddress.emit(place);
  }

}
