import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "../../helpers/data.service";
import { User } from "src/app/models/user";
import { AddressesOrderService } from "../../services/addressesOrder.service";
import { AddressOrder } from "../../models/address-order";
import { first } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-address-order-list",
  templateUrl: "./address-order-list.component.html",
  styleUrls: ["./address-order-list.component.css"],
})
export class AddressOrderListComponent implements OnInit {
  currentUser: User;
  orders: AddressOrder[] = [];
  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private addressesOrderService: AddressesOrderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    setTimeout(() => this.dataService.enableLoader(true));
    this.currentUser = this.dataService.state.user;
    const { snapshot } = this.route;

    let userId = this.currentUser.id;
    if (this.currentUser.type == "Headoffice" && snapshot.queryParams.id) {
      userId = snapshot.queryParams.id;
    }

    this.addressesOrderService
      .ordersByUser(userId)
      .pipe(first())
      .subscribe(
        (orders) => {
          this.orders = orders;
          this.dataService.enableLoader(false);
        },
        (error) => console.error(error)
      );
  }

  complete(order: AddressOrder) {
    if (order.status !== "initialized") {
      throw Error("Cannot complete an order that has already been ordered");
    }
    this.dataService.setAddressOrder(order);
    this.router.navigate(["/order-addresses"]);
  }
}
