<div class="row">
  <div class="col s12">
    <div class="card">
      <div class="card-content black-text elm-card">
        <div *ngIf="allElms.length > 0">
          <div class="part-header">{{ "elm.listTitle" | translate }}</div>
          <div class="elm-list">
            <table>
              <tbody>
                <tr *ngFor="let elm of allElms">
                  <th>
                    <ng-container *ngIf="elm.dep">
                      {{ "elm.depItem" | translate: { dep: elm.dep } }}
                    </ng-container>
                    <ng-container *ngIf="elm.irises.length > 0">
                      {{ "elm.irisItem" | translate: { count: elm.irises.length } }}
                    </ng-container>
                    <ng-container *ngIf="elm.zipCodes.length > 0">
                      {{ "elm.zipCodeItem" | translate: { count: elm.zipCodes.length } }}
                    </ng-container>
                    {{ elm.date | date: "dd/MM/yyyy" }}
                  </th>
                  <th>
                    <button
                      mat-stroked-button
                      *ngIf="elm.fileKey"
                      (click)="downloadElm(elm)"
                      [disabled]="loading"
                    >
                      {{ "elm.download" | translate }}
                    </button>
                    <ng-container *ngIf="!elm.fileKey">
                      <button
                        mat-stroked-button
                        *ngIf="elm.status === 'PAYED' || elm.status === 'ORDERED'"
                        (click)="generateElm(elm)"
                        [disabled]="loading"
                      >
                        {{ "elm.generate" | translate }}
                      </button>
                      <button
                        mat-stroked-button
                        *ngIf="elm.status === 'WAITING_FOR_HO'"
                        [disabled]="true"
                      >
                        {{ "elm.waitingForHo" | translate }}
                      </button>
                      <button
                        mat-stroked-button
                        *ngIf="elm.status === 'DENIED'"
                        [disabled]="true"
                      >
                        {{ "elm.denied" | translate }}
                      </button>
                    </ng-container>
                    <!-- <button
                      mat-stroked-button
                      *ngIf="elm.fileKey"
                      (click)="generateElm(elm)"
                      [disabled]="loading"
                    >
                      {{ "elm.generate" | translate }}
                    </button> -->
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="part-header">{{ "elm.generateTitle" | translate }}</div>
        <mat-tab-group *ngIf="!loading" mat-align-tabs="start">
          <mat-tab label='{{ "elm.tabs.dep" | translate }}'>
            <mat-form-field appearance="fill">
              <mat-label>{{ "elm.depSelect" | translate }}</mat-label>
              <mat-select [(value)]="selectedDepCode">
                <mat-option *ngFor="let dep of depData" [value]="dep.code">
                  {{ dep.code }} ({{ dep.name }})
                </mat-option>
              </mat-select>
            </mat-form-field>
            &nbsp;
            <button
              mat-raised-button
              color="primary"
              [disabled]="!selectedDepCode"
              (click)="openOrderDialog('DEP')"
            >
              {{ "elm.order" | translate }}
            </button>
          </mat-tab>
          <mat-tab label='{{ "elm.tabs.iris" | translate }}'>
            <button mat-stroked-button class="importButton" type="button" (click)="fileIrisInput.click()">{{ "elm.importFile" | translate }}</button>
            <input hidden (change)="onIrisesFileLoad($event)" #fileIrisInput type="file" accept=".csv" id="fileIris">
            &nbsp;
            <button
              mat-raised-button
              color="primary"
              [disabled]="!selectedIrises || selectedIrises.length === 0"
              (click)="openOrderDialog('IRISES')"
            >
              {{ "elm.order" | translate }}
            </button>
            <p *ngIf="irisFileName" class="importConfirm">{{ "elm.importedIrisFile" | translate: { file: irisFileName, count: selectedIrises.length } }}</p>
            <p class="instructions">{{ "elm.irisImportInstructions" | translate }}</p>
          </mat-tab>
          <mat-tab label='{{ "elm.tabs.zipCodes" | translate }}'>
            <button mat-stroked-button class="importButton" type="button" (click)="fileZipCodeInput.click()">{{ "elm.importFile" | translate }}</button>
            <input hidden (change)="onZipCodesFileLoad($event)" #fileZipCodeInput type="file" accept=".csv" id="fileZipCode">
            &nbsp;
            <button
              mat-raised-button
              color="primary"
              [disabled]="!selectedZipCodes || selectedZipCodes.length === 0"
              (click)="openOrderDialog('ZIPCODES')"
            >
              {{ "elm.order" | translate }}
            </button>
            <p *ngIf="zipCodeFileName" class="importConfirm">{{ "elm.importedZipCodeFile" | translate: { file: zipCodeFileName, count: selectedZipCodes.length } }}</p>
            <p class="instructions">{{ "elm.zipCodeImportInstructions" | translate }}</p>
          </mat-tab>
        </mat-tab-group>
        <mat-progress-bar
          *ngIf="loading"
          mode="indeterminate"
        ></mat-progress-bar>
        <div *ngIf="loading" class="legend">
          {{ loadingMessage }}
        </div>
        <div *ngIf="errorMessage" class="error legend">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</div>
<div id="generate-container"></div>

<ng-template #orderModal let-data>
  <h2 matDialogTitle>{{ "elm.modal.title" | translate }}</h2>
  <mat-dialog-content>
    <p>{{ "elm.modal.message" | translate }}</p>
    <table>
      <thead>
        <tr>
          <th>{{ "elm.table.product" | translate }}</th>
          <th>{{ "elm.table.quantity" | translate }}</th>
          <th>{{ "elm.table.preTaxPrice" | translate }}</th>
          <th>{{ "elm.table.vatPrice" | translate }}</th>
          <th>{{ "elm.table.totalPrice" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="data.elmType === 'DEP'">
          <th>{{ "elm.modal.depElm" | translate }} - dep #{{ data.dep }}</th>
          <th>1</th>
          <th>{{ pricing.price_dep | currency: "EUR" }}</th>
          <th>
            {{ pricing.price_dep * 0.2 | currency: "EUR" }}
          </th>
          <th>
            {{ pricing.price_dep * 1.2 | currency: "EUR" }}
          </th>
        </tr>
        <tr *ngIf="data.elmType === 'IRISES'">
          <th>{{ "elm.modal.irisElm" | translate }}</th>
          <th>{{ selectedIrises.length }}</th>
          <th>{{ pricing.price_iris * selectedIrises.length | currency: "EUR" }}</th>
          <th>
            {{ pricing.price_iris * selectedIrises.length * 0.2 | currency: "EUR" }}
          </th>
          <th>
            {{ pricing.price_iris * selectedIrises.length * 1.2 | currency: "EUR" }}
          </th>
        </tr>
        <tr *ngIf="data.elmType === 'ZIPCODES'">
          <th>{{ "elm.modal.zipCodeElm" | translate }}</th>
          <th>{{ selectedZipCodes.length }}</th>
          <th>{{ pricing.price_zipcode * selectedZipCodes.length | currency: "EUR" }}</th>
          <th>
            {{ pricing.price_zipcode * selectedZipCodes.length * 0.2 | currency: "EUR" }}
          </th>
          <th>
            {{ pricing.price_zipcode * selectedZipCodes.length * 1.2 | currency: "EUR" }}
          </th>
        </tr>
      </tbody>
    </table>
    <ngx-paypal
      *ngIf="data.payPalConfig"
      className="paypal-container"
      mat-button
      [config]="data.payPalConfig"
    ></ngx-paypal>
    <p *ngIf="!data.payPalConfig && data.price.value !== '0.00'">
      {{ "elm.modal.paymentConfirmationMessage" | translate }}
      {{ data.price.value | currency: "EUR" }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ "global.close" | translate }}
    </button>
    <button
      *ngIf="!data.payPalConfig"
      mat-raised-button
      color="primary"
      (click)="openConfirmationModal(data)"
    >
      {{ "elm.modal.validate" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #confirmationModal let-data>
  <h2 matDialogTitle>{{ "elm.confirmationModal.title" | translate }}</h2>
  <mat-dialog-content>
    <p>{{ "elm.confirmationModal.message" | translate }}</p>
    <p>{{ "elm.confirmationModal.confirmOrder" | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ "global.close" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="onPaymentSuccess(data.elmType, data.price, data.price.value === '0.00')"
    >
      {{ "global.submit" | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
