<div class="container">
    <h3 class='page-title'>{{'recapitulate.summary' | translate}}</h3>

    <div class="row">
        <table *ngIf='campaign.address' class='white-text table-striped  no-collapse'>
            <tbody>
                <tr>
                    <td colspan="2">{{'global.address' | translate}}: {{campaign.address.full_address}}</td>
                </tr>
               <!--<tr>
                    <td>radius or selected region: {{campaign.radius}} km</td>
                    <td>{{'global.nb_address_total' | translate}}: {{campaign.numberOfAddresses}}</td>
                </tr>
                <tr>
                    <td>Envelope format {{campaign.postage.selectedEnvelope.name}}</td>
                    <td>flyer format {{campaign.postage.selectedPaper.name}}</td>
                </tr>-->
            </tbody>
        </table>
    </div>

    <div class="row">
        <table class="white-text table-striped  no-collapse">
            <tbody>
                <tr>
                    <td>{{'recapitulate.execution-date' | translate}}</td>
                    <td>
                        <mat-form-field>
                            <input class="date-input" matInput [min]='orderMinDate' [matDatepicker]="picker" placeholder="{{'recapitulate.choose-date' | translate}}" [formControl]='defaultDate' (dateChange)='order.date = $event.value'>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>{{'recapitulate.nb-of-adr' | translate}}</td>
                    <td>{{campaign.numberOfAddresses}}</td>
                </tr>
                <!--<tr>
                    <td>Postage price to pay</td>
                    <td>{{ order.postagePrice | currency: 'EUR'}}</td>
                </tr>-->
                <tr *ngIf='order.offeredAddresses'>
                    <td>{{'recapitulate.offered-adr' | translate}}</td>
                    <td>{{order.offeredAddresses | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <td>{{'recapitulate.removed-adr' | translate}}</td>
                  <td>
                    <ng-container *ngIf='removedAddresses > 0'>{{removedAddresses}}</ng-container>
                    <ng-container *ngIf='removedAddresses === 0'>{{"recapitulate.no-address" | translate}}</ng-container>
                    &nbsp;<div class='btn-small poolspot-blue edit-button' (click)='removeAddresses()'>{{'global.edit' | translate}}</div>
                  </td>
                </tr>
                <tr>
                  <td>{{'recapitulate.verif-adr' | translate}}</td>
                  <td>
                    <ng-container *ngIf='campaign.verification_addresses && campaign.verification_addresses.length > 0'>{{campaign.verification_addresses.length}}</ng-container>
                    <ng-container *ngIf='!campaign.verification_addresses || campaign.verification_addresses.length === 0'>{{"recapitulate.no-address" | translate}}</ng-container>
                    &nbsp;<div class='btn-small poolspot-blue edit-button' (click)='openAddressModal()'>{{'global.edit' | translate}}</div>
                  </td>
                </tr>
                <tr>
                    <td>{{'recapitulate.price-excluding-vat' | translate}}</td>
                    <td>{{order.HTPrice | currency: 'EUR'}}</td>
                </tr>
                <tr>
                    <td>{{'recapitulate.vat' | translate}}</td>
                    <td>{{order.taxes | currency: 'EUR'}}</td>
                </tr>
                <tr>
                    <td><h4>{{'recapitulate.final-price' | translate}}</h4></td>
                    <td><h4>{{order.totalPrice | currency: 'EUR'}}</h4></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row col s12 center-align">
        <ngx-paypal *ngIf='(user.makePayment && order.totalPrice > 0) else orderButton'  [config]="payPalConfig"></ngx-paypal>
    </div>
</div>


<ng-template #orderButton>
    <button class="rounded-button col s10 offset-s1" (click)='handleOrder()'>{{'recapitulate.order' | translate}}</button>
</ng-template>


<ng-template #modalInfo>

        <h2 mat-dialog-title>{{'recapitulate.modal.title' | translate}}</h2>
        <mat-dialog-content class="mat-typography">
          <h3>{{'recapitulate.modal.campaign' | translate}}: {{campaign.name}} - {{campaign.id}}</h3>
          <p [translate]='"recapitulate.modal.content"' [translateParams]="{id: campaign.id, name: campaign.name}">
          </p>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>{{'global.close' | translate}}</button>
        </mat-dialog-actions>

</ng-template>

<ng-template #addressModal>

  <h2 mat-dialog-title>{{'recapitulate.addressModal.title' | translate}}</h2>
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="campaign.verification_addresses && campaign.verification_addresses.length > 0">
      <h3>{{'recapitulate.verif-adr' | translate}}</h3>
      <ul class="verif-adr-list">
        <li *ngFor="let address of campaign.verification_addresses, let i = index" class="adress-list-item">
          M. et Mme le propriétaire, {{ address.numero }}&nbsp;{{address.rep}} {{address.nom_voie}},
          {{ address.code_post }} {{ address.nom_com }}&nbsp;
          <div class="btn-flat waves-effect btn-floating" (click)="removeVerifAddress(i)"><i class="material-icons">clear</i></div>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="!campaign.verification_addresses || campaign.verification_addresses.length < 3">
      <h3 class="add-address-title">{{'recapitulate.addressModal.add-address' | translate}}</h3>
      <form [formGroup]='verifAddressForm' (ngSubmit)='addVerifAddress()' class="verif-form col s12">
        <div class="input-field inline">
          M. et Mme le propriétaire,
        </div><br/>
        <div class="input-field inline">
          <input type="number" name="numero" id="numero" formControlName="numero" step="1" min="1" max="1000" required class="validate"/>
          <label for="numero">
            {{'recapitulate.addressModal.numero' | translate}}
          </label>
        </div>
        <div class="input-field inline">
          <input type="text" name="rep" id="rep" formControlName="rep" class="validate"/>
          <label for="rep">
            {{'recapitulate.addressModal.rep' | translate}}
          </label>
        </div>
        <div class="input-field inline">
          <input type="text" name="nom_voie" id="nom_voie" formControlName="nom_voie" required class="validate"/>
          <label for="nom_voie">
            {{'recapitulate.addressModal.nom_voie' | translate}}
          </label>
        </div>, <br/>
        <div class="input-field inline">
          <input type="text" name="code_post" id="code_post" formControlName="code_post" required class="validate"/>
          <label for="code_post">
            {{'recapitulate.addressModal.code_post' | translate}}
          </label>
        </div>
        <div class="input-field inline">
          <input type="text" name="nom_com" id="nom_com" formControlName="nom_com" required class="validate"/>
          <label for="nom_com">
            {{'recapitulate.addressModal.nom_com' | translate}}
          </label>
        </div><br/>
        <button class="btn-small waves-effect waves-light" type="submit" [disabled]="!verifAddressForm.valid" name="action">
          <i class="material-icons right">add</i>
          {{'global.add' | translate}}
        </button>
      </form>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'global.close' | translate}}</button>
  </mat-dialog-actions>

</ng-template>
