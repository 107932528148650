const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide();

  slide.addImage({
    path: "assets/images/elm/cover1.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  slide.addImage({
    path: "assets/images/elm/logo_pixstart.png",
    x: 6.98,
    y: 0.1,
    w: 1.1,
    h: 0.47,
  });

  if (elmGenerator.logo) {
    slide.addImage({
      data: elmGenerator.logo,
      x: 6.66,
      y: 4.04,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1.72,
        h: 1.15,
      },
    });
  }

  slide.addText("From space data to strategic decisions", {
    x: 5.92,
    y: 0.5,
    w: 3.21,
    h: 0.47,
    align: "center",
    italic: true,
    fontFace: "Georgia",
    fontSize: 10,
    color: "008FC9",
  });

  let title = "État local de marché sur ";
  if (elmGenerator.dep) {
    title += `le département ${elmGenerator.dep}`;
  } else {
    // irises or zipCodes
    const allDeps = [
      ...new Set(
        elmGenerator.irisFeatures.map((feat) => feat.properties.code_dep)
      ),
    ];
    if (allDeps.length === 1) {
      title += `un sous ensemble du département ${allDeps[0]}`;
    } else {
      const [first, ...rest] = allDeps;
      const deps = `${rest.join(", ")} et ${first}`
      title += `un sous ensemble des départements ${deps}`;
    }
  }

  slide.addText(title, {
    x: 5.8,
    y: 1.28,
    w: 3.57,
    h: 2.44,
    align: "center",
    fontFace: "Dosis",
    fontSize: 32,
    color: "9BCF63",
  });
};

export default addSlide;
