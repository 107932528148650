import { Component, OnInit, ViewChild, TemplateRef, NgZone } from "@angular/core";
import { Campaign } from "../../models/campaign";
import { DataService } from "src/app/helpers/data.service";
import { Order } from "../../models/order";
import { IPayPalConfig, ICreateOrderRequest } from "ngx-paypal";
import { environment } from "../../../environments/environment";
import { MatDialog, MatSnackBar } from '@angular/material';
import { CampaignStatus } from '../../utils/campaign-status';
import { User } from 'src/app/models/user';
import { CampaignService } from 'src/app/services/campaign.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { postCodeValidator } from "../../helpers/validators";

@Component({
  selector: "app-recapitulate",
  templateUrl: "./recapitulate.component.html",
  styleUrls: ["./recapitulate.component.css"]
})
export class RecapitulateComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;

  campaign: Campaign;
  user: User;
  orderMinDate: Date = new Date();
  defaultDate: FormControl;
  verifAddressForm: FormGroup;
  order: Order = {};
  removedAddresses: number = 0;

  @ViewChild("modalInfo", { static: false }) modalInfo: TemplateRef<any>;
  @ViewChild("addressModal", { static: false }) addressModal: TemplateRef<any>;

  constructor(
    private data: DataService,
    private dialog: MatDialog,
    private campaignService: CampaignService,
    private router: Router,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public translate : TranslateService,
    private ngZone: NgZone
  ) {
    // TODO remove this line after september 6
    const minGlobalDate = new Date(2021, 8, 6, 12);
    this.orderMinDate.setDate(this.orderMinDate.getDate() + 22);
    if(this.orderMinDate < minGlobalDate){
      this.orderMinDate = minGlobalDate;
    }
    this.defaultDate = new FormControl(this.orderMinDate);

    this.order.date = this.defaultDate.value;


    this.verifAddressForm = this.formBuilder.group({
      numero: new FormControl(null, Validators.required),
      rep: new FormControl(''),
      nom_voie: new FormControl('', Validators.required),
      nom_com: new FormControl('', Validators.required),
      code_post: new FormControl('', Validators.compose([
        Validators.required,
        postCodeValidator
      ])),
    });
  }

  ngOnInit() {
    this.campaign = this.data.state.campaign;
    this.user = this.data.state.user;
    this.computePrice();


    this.initConfig();
  }

  addVerifAddress(){
    if(!this.verifAddressForm.valid) return;
    const verifAddress = {
      ...this.verifAddressForm.value
    }
    verifAddress.numero = String(verifAddress.numero);
    if(!this.campaign.verification_addresses){
      this.campaign.verification_addresses = []
    }
    this.campaign.verification_addresses.push(verifAddress);
    this.data.setCampaign(this.campaign);
    this.verifAddressForm.reset();
  }

  removeVerifAddress(index){
    this.campaign.verification_addresses.splice(index, 1);
    this.data.setCampaign(this.campaign);
  }

  computePrice(){
    if(this.campaign.removed_addresses) {
      this.removedAddresses = this.campaign.removed_addresses.length;
    } else if(this.campaign.removedAddresses) {
      this.removedAddresses = this.campaign.removedAddresses;
    }

    const verifAddressNumber = this.campaign.verification_addresses ? this.campaign.verification_addresses.length : 0;
    const totalAddressNumber = this.campaign.numberOfAddresses - this.removedAddresses + verifAddressNumber;

    this.order.postagePrice = 0;
    if(this.user.flyerStep) {
      this.order.postagePrice = totalAddressNumber * this.campaign.postage.price;
    }
    this.order.HTPrice = 0;
    if(this.campaign.price){
      this.order.HTPrice = this.campaign.price + this.order.postagePrice;
      if(this.user.type === 'Affiliate' && this.user.reduction && this.user.reduction > 0){
        this.order.offeredAddresses = Math.min(this.order.HTPrice, this.user.reduction);
        this.order.HTPrice -= this.order.offeredAddresses;
      }
    }
    const VATRate = this.data.state.user.countries.find(country => country.name === "France").tva;
    this.order.taxes =  this.order.HTPrice * VATRate;

    this.order.totalPrice = this.order.HTPrice + this.order.taxes;
    this.campaign.order = this.order;

    this.data.setCampaign(this.campaign);
  }



  private initConfig(): void {
    this.payPalConfig = {
      currency: "EUR",
      clientId: environment.paypal_client_id,
      createOrderOnClient: data =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "EUR",
                value: this.order.totalPrice.toFixed(2)
              }
            }
          ]
        },
      advanced: {
        commit: "true"
      },
      style: {
        label: "paypal",
        layout: "vertical"
      },
      onApprove: data => null,
      onClientAuthorization: data => {
        if(data.status === 'COMPLETED')
        {
         setTimeout(() => this.handleOrder());
        }
        else {
          this.snackbar.open('Il semble que votre transaction n as pas ete effectuer veuillez verifier  votre compte paypal svp', this.translate.instant("global.close"),
          {
            duration: 10000
          })
        }

      },
      onError: err => {
        this.data.setCampaign(null);
        this.authService.logout();
        this.snackbar.open(
          this.translate.instant('global.error-message'),
          this.translate.instant("global.close"),
          { duration: 4000 }
        );
        this.router.navigate(["/"]);
      }
    };
  }

  openAddressModal() {
    this.dialog.open(this.addressModal)
    .afterClosed()
    .subscribe(() => {
      this.verifAddressForm.reset();
      this.campaignService
        .update(this.campaign)
        .subscribe(campaign => {
          this.campaign = campaign;
          this.campaign.id = campaign['_id'];
          this.data.setCampaign(this.campaign);
          this.computePrice();
        })

    });;
  }

  openDialog() {
    this.ngZone.run(() => {
      this.dialog
      .open(this.modalInfo, { disableClose: true })
      .afterClosed()
      .subscribe(() => {
        this.data.setCampaign(null);
        this.router.navigate(["/"], {replaceUrl: true});
      });
    })
  }

  handleOrder() {
    this.data.enableLoader(true);
    this.campaign.status = CampaignStatus.ordered;
    if(this.user.subscription && this.user.type === 'Affiliate') {
      this.campaign.status = CampaignStatus.waitingForHOConfirmation;
    } else if(!this.user.subscription && this.user.makePayment) {
      this.campaign.status = CampaignStatus.payed;
    }
    this.campaign.language = this.translate.currentLang;
    this.data.setCampaign(this.campaign);
    this.campaignService.update(this.campaign).subscribe(
      data => {
        if(this.order.offeredAddresses && this.order.offeredAddresses > 0)  {
          this.user.reduction -= this.campaign.order.offeredAddresses;

          this.data.setUser(this.user);
        }
        this.openDialog();
          this.data.enableLoader(false);

      },
      error => {
        console.error(error);
        this.data.enableLoader(false);
      }
    );
  }


  removeAddresses(){
    this.router.navigate(['/remove-addresses']);
  }
}
