import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/helpers/data.service';
import { User } from '../../models/user';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentUser: User
  constructor(translate: TranslateService, private dataService: DataService) { }

  ngOnInit() {
    this.currentUser = this.dataService.state.user;
    // This resets saved campaign when user go back to home menu
    this.dataService.setCampaign(null)
  }

}
