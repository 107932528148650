<div class="section"></div>
<pitch></pitch>
<div class="section"></div>
<div class="section"></div>
<div class="container">
  <div class="row">
    <a
      routerLink="/prospection"
      mat-raised-button
      class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
      >{{ "home.prospect" | translate }}</a
    >
    <a
      *ngIf="currentUser.accessDataVis"
      routerLink="/geo-strategy"
      mat-raised-button
      class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
      >{{ "home.geo-strat" | translate }}</a
    >
    <a
      *ngIf="currentUser.accessElm && (currentUser.elmPricing || currentUser?.headOffice?.elmPricing)"
      routerLink="/elm"
      mat-raised-button
      class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
      >{{ "home.elm" | translate }}</a
    >
    <span *ngIf="currentUser.type == 'Headoffice'; else otherButtons">
      <a
        routerLink="/administration"
        mat-raised-button
        class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
        >{{ "home.administration" | translate }}</a
      >
    </span>

    <ng-template #otherButtons>
      <a
        routerLink="/campaigns"
        mat-raised-button
        class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
        >{{ "home.campaign-list" | translate }}</a
      >
      <a
        *ngIf="currentUser.buyAddresses"
        routerLink="/address-orders"
        mat-raised-button
        class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
        >{{ "address-order-list.summary" | translate }}</a
      >
    </ng-template>
  </div>
</div>
