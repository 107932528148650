<div class="container">
    <div class="row">

    <div class="card">
        <div class='card-content'>
        <form [formGroup]='affiliateForm'  (ngSubmit)='onSubmit()'>

                <div class='input-field col s12'>
                    <input class='validate' type='text' name='username' id='username' formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" required autocomplete="new-username"/>
                    <label class='active' for='username'>{{'affiliate.form.input.username' | translate}}</label>

                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.username.errors.required ">{{'affiliate.form.message.username' | translate}}</div>
                        <div *ngIf="f.username.errors.isUniqueUsername ">{{'affiliate.form.message.username-already-exists' | translate}}</div>
                    </div>
                </div>


                <div class='input-field col s12'>
                    <input class='validate' type='password' name='password' id='password' formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  autocomplete="new-password"/>
                    <label class='active' for='password'>{{'affiliate.form.input.password' | translate}}</label>

                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.password.errors.required">{{'affiliate.form.message.password' | translate}}</div>
                        <div *ngIf="f.password.errors.incorrect">Password incorrect</div>

                    </div>
                    <mat-password-strength
                                #passwordComponent
                                [password]="f.password.value"
                                [min]='8'
                                [enableSpecialCharRule]='false'
                                (onStrengthChanged)="onStrengthChanged($event)"
                                >
                    </mat-password-strength>

                    <mat-password-strength-info
                    [lowerCaseCriteriaMsg]="'change-password.LOWER_CASE_CRITERIA_MSG' | translate"
                    [upperCaseCriteriaMsg]="'change-password.UPPER_CASE_CRITERIA_MSG'| translate"
                    [digitsCriteriaMsg]="'change-password.DIGITS_CRITERIA_MSG'| translate"
                    [specialCharsCriteriaMsg]="'change-password.SPECIAL_CHARS_CRITERIA_MSG' | translate"
                    [minCharsCriteriaMsg]="'change-password.MIN_CHARS_CRITERIA_MSG' | translate"
                    [passwordComponent]="passwordComponent">
                    </mat-password-strength-info>-
                </div>

                <div class='input-field col s12'>
                    <input class='validate' type='password' name='confirmPassword' id='confirmPassword' formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                    <label class='active' for='confirmPassword'>{{'affiliate.form.input.confirmPassword' | translate}}</label>

                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.confirmPassword.errors.required ">{{'affiliate.form.message.confirmPassword' | translate}}</div>
                    </div>
                    <div  *ngIf='submitted && affiliateForm.hasError("passwordNotEqual")' class="invalid-feedback red-text">
                        Password not equal !
                    </div>
                </div>


                <div class='input-field col s12'>
                    <input class='validate' type='email' name='email' id='email' formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required/>
                    <label  class='active' for='email'>{{'affiliate.form.input.email' | translate}}</label>

                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.email.errors.required ">{{'affiliate.form.message.email' | translate}}</div>
                        <div *ngIf="f.email.errors.email">{{'affiliate.form.message.valid-email' | translate}}</div>
                    </div>
                </div>

                <div class='input-field col s12'>
                    <input class='validate' type='text' name='company' id='company' formControlName="company" [ngClass]="{ 'is-invalid': submitted && f.company.errors }" required/>
                    <label class='active'  for='company'>{{'affiliate.form.input.company' | translate}}</label>

                    <div  *ngIf="submitted && f.company.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.company.errors.required ">{{'affiliate.form.message.company' | translate}}</div>
                    </div>
                </div>

                <div class='input-field col s12'>
                    <input class='validate' type='text' name='companyAddress' id='companyAddress' formControlName="companyAddress" [ngClass]="{ 'is-invalid': submitted && f.companyAddress.errors }" required/>
                    <label class='active'  for='companyAddress'>{{'affiliate.form.input.companyAddress' | translate}}</label>

                    <div  *ngIf="submitted && f.companyAddress.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.companyAddress.errors.required ">{{'affiliate.form.message.companyAddress' | translate}}</div>
                    </div>
                </div>

                <div class='input-field col s12'>
                    <input class='validate' type='text' name='siret' id='siret' formControlName="siret" [ngClass]="{ 'is-invalid': submitted && f.siret.errors }" required/>
                    <label class='active'  for='siret'>{{'affiliate.form.input.siret' | translate}}</label>

                    <div  *ngIf="submitted && f.siret.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.siret.errors.required ">{{'affiliate.form.message.siret' | translate}}</div>
                    </div>
                </div>

                <div class='input-field col s12'>
                    <input class='validate' type='text' name='phone' id='phone' formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" required/>
                    <label class='active' for='phone'>{{'affiliate.form.input.phone' | translate}}</label>

                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.phone.errors.required ">
                            {{'affiliate.form.message.phone' | translate}}
                        </div>
                        <div *ngIf="f.phone.errors.pattern ">
                            {{'affiliate.form.message.valid-phone' | translate}}
                        </div>
                    </div>
                </div>

            <div class="row center-align">
                <button type="submit" class='btn rounded-button'>{{'global.submit' | translate}}</button>
            </div>
        </form>
        </div>
    </div>

    </div>
</div>
