const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover4.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if (elmGenerator.logo) {
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61,
      },
    });
  }

  slide.addText("Lexique (2/2)", {
    x: 4.18,
    y: 0.64,
    w: 5.32,
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "9BCF63",
  });

  slide.addText(
    [
      {
        text: "Ratio Familles",
        options: { fontSize: 12, breakLine: true },
      },
      {
        text: `Le ratio de familles est le rapport entre le nombre de familles et le nombre de ménages.`,
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      {
        text: "Ratio Population de retraités",
        options: { fontSize: 12, breakLine: true },
      },
      {
        text: "Le ratio de population retraité est le rapport entre le nombre de personnes retraités et le nombre de personnes de plus de 15 ans.",
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      {
        text: "Ratio habitat de type maison principale",
        options: { fontSize: 12, breakLine: true },
      },
      {
        text: "Le ratio d’habitat de type maison principale est le rapport entre le nombre d’habitat de type maison principale et le nombre de logements.",
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      {
        text: "IRIS",
        options: { fontSize: 12, breakLine: true },
      },
      {
        text: "Un ",
      },
      {
        text: "IRIS",
        options: {
          hyperlink: {
            url: "https://www.insee.fr/fr/metadonnees/definition/c1523",
          },
          color: "9BCF63",
        },
      },
      {
        text: " est une unité géographique représentant généralement une commune ou un quartier d'environ 2000 habitants.",
        options: { breakLine: true },
      },
    ],
    {
      x: 4.18,
      y: 1.39,
      w: 5.69,
      h: 3.26,
      fontFace: "Pontano Sans",
      fontSize: 10,
      color: "484F56",
      valign: "top",
      paraSpaceAfter: 3,
    }
  );
};

export default addSlide;
