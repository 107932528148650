const summary = {
  Introduction: ["Source des données", "Lexique", "Zone d'étude"],
  "Revenu médian": [],
  "Typologie population": ["CSP+", "Familles", "Retraités"],
  Habitat: [],
  "Données Piscines": [],
  "Données sur la concurrence": [],
};

const summary2TextDef = (summary: { [title: string]: string[] }) => {
  const res = [];

  for (const [title, children] of Object.entries(summary)) {
    res.push({
      text: " ",
      options: {
        bold: true,
        fontSize: 15,
        bullet: { type: "number" },
        color: "9BCF63",
      },
    });
    res.push({
      text: title,
      options: {
        bold: true,
        fontSize: 15,
      },
    });
    for (const child of children) {
      res.push({
        text: " ",
        options: {
          indentLevel: 1,
          bullet: { type: "number", style: "alphaLcPeriod" },
          color: "9BCF63",
        },
      });
      res.push({ text: child });
    }
  }
  return res;
};

const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover3.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if(elmGenerator.logo){
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61
      }
    })
  }

  slide.addText("Sommaire", {
    x: 4.68,
    y: 0.39,
    w: 5.32,
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "9BCF63",
  });

  slide.addText(summary2TextDef(summary), {
    x: 5.41,
    y: 1.54,
    w: 3.19,
    h: 2.06,
    fontFace: "Pontano Sans",
    fontSize: 13,
    color: "484F56",
    valign: "top",
  });
};

export default addSlide;
