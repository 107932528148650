<div class="container">
    <div class="row">
        
            <div class="card">
                <div class="card-content">
                    <div class="row">

                        <div class="input-field col s12">
                            <input  id='oldPassword' type="password" class="validate" [(ngModel)]='oldPassword'>
                            <label for="oldPassword">{{'change-password.old' | translate}}</label>
                            <div *ngIf='wrongOldPassword' class='red-text helper-text'>
                                {{'change-password.incorrect' | translate}}
                            </div>
                        </div>

                        <div class="input-field col s12">
                            <input id='newPassword' type="password" class="validate" [(ngModel)]='password'>
                            <label for="newPassword">{{'change-password.new' | translate}}</label>    
                            <mat-password-strength 
                                #passwordComponent 
                                [password]="password" 
                                [min]='8'
                                [enableSpecialCharRule]='false'
                                (onStrengthChanged)="onStrengthChanged($event)">
                            </mat-password-strength>

                            <mat-password-strength-info
                            [lowerCaseCriteriaMsg]="'change-password.LOWER_CASE_CRITERIA_MSG' | translate"
                            [upperCaseCriteriaMsg]="'change-password.UPPER_CASE_CRITERIA_MSG'| translate"
                            [digitsCriteriaMsg]="'change-password.DIGITS_CRITERIA_MSG'| translate"
                            [specialCharsCriteriaMsg]="'change-password.SPECIAL_CHARS_CRITERIA_MSG' | translate"
                            [minCharsCriteriaMsg]="'change-password.MIN_CHARS_CRITERIA_MSG' | translate"
                            [passwordComponent]="passwordComponent">
                            </mat-password-strength-info>-
                        </div>


                        <div class="input-field col s12">
                            <input id='confirmPassword'  type="password" class="validate" [(ngModel)]='confirmPassword'>
                            <label for="confirmPassword">{{'change-password.confirm' | translate}}</label>
                            <div *ngIf='confirmPassword !== password && confirmPassword !== ""' class='red-text'>
                                {{'change-password.incorrect-confirm' | translate }}
                            </div>
                        </div>    

                        <div class="col s12 center-align">
                            <button class="btn rounded-button" 
                            (click)='changePassword()'
                            [disabled]='!passwordValid || confirmPassword !== password || password === ""'>
                                {{'global.submit' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
              
            
        
        
    </div>
</div>






  