<div class="row">
  <div class="col s12">
    <div class="card">
      <div class="card-content black-text">
        <button class="save-button" mat-icon-button (click)="save()" [disabled]='selectedFeatures.length === 0'>
          <mat-icon>save</mat-icon>
        </button>

        <div class="container">
          <div class="row">
            <div class="col s4 m4">
              <Autocomplete (setAddress)="setAddress($event)"></Autocomplete>
            </div>
            <div class="input-field center-align col s8 m8">
              <mat-radio-group aria-label="Select an option" (change)="changeLayer($event.value)">
                <mat-radio-button class='' [checked]='true' value="existing">
                  {{'geo-strategy.existing' | translate}}</mat-radio-button>
                <mat-radio-button class='' value="potentiel">{{'geo-strategy.potentiel' | translate}}
                </mat-radio-button>
                <mat-radio-button class='' value="growth">{{'geo-strategy.growth' | translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="row">
          <mat-sidenav-container [hasBackdrop]='false'>
            <mat-sidenav mode="side" #sidenav [(opened)]='mapSideNavOpen'>
              <mat-accordion [displayMode]='"flat"'>

                <mat-expansion-panel (opened)="accordionSteps = 2">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'global.filters.insee' | translate}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="center-align">
                    <ul>
                      <li class='row valign-wrapper' *ngFor="let statsItem of inseeStats">
                        <div class="col s3">{{'geo-strategy.stats.' + statsItem.id | translate}}</div>
                        <div class="col s9">
                          <filter-slider [minValue]='statsItem.minValue' [maxValue]='statsItem.maxValue' (range)='onFilter($event, statsItem)'>
                          </filter-slider>
                        </div>
                      </li>
                    </ul>
                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel (opened)="accordionSteps = 1">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{'global.filters.pools' | translate}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="center-align">
                    <ul>
                      <li class='row valign-wrapper' *ngFor="let statsItem of poolsStats">
                        <div class="col s3">{{'geo-strategy.poolsStats.' + statsItem.id | translate}}</div>
                        <div class="col s9">
                          <filter-slider [minValue]='statsItem.minValue' [maxValue]='statsItem.maxValue' (range)='onFilter($event, statsItem)'>
                          </filter-slider>
                        </div>
                      </li>
                    </ul>
                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]='accordionSteps == 0'>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Données INSEE
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div *ngIf='clickedFeature'>

                    <h5 class="center-align">{{clickedFeature.nom_zone}}</h5>
                    <h6 class="center-align"
                      *ngIf='clickedFeature.lib_com && clickedFeature.nom_zone !== clickedFeature.lib_com'>
                      {{clickedFeature.lib_com}}
                      <ng-container *ngIf="clickedFeature.cp && clickedFeature.cp !== '0'" > ({{clickedFeature.cp}})</ng-container>
                    </h6>
                    <h6 class="center-align"
                      *ngIf='clickedFeature.cp && clickedFeature.nom_zone == clickedFeature.lib_com'>
                      ({{clickedFeature.cp}})</h6>
                    <div class="center-align subtitle">{{'geo-strategy.inseeDate' | translate }}</div>


                    <ul>
                      <li class='row valign-wrapper insee-element' *ngFor="let statsItem of inseeStats">
                        <ng-container *ngIf="clickedFeature[statsItem.id] as value">
                          <ng-container *ngIf="clickedFeature[statsItem.id + '_d'] as diff">
                            <div class='col s6 title'>{{'geo-strategy.stats.' + statsItem.id | translate}}</div>
                            <div class='col s6 center-align'>
                              <div *ngIf="statsItem.type === 'currency'">{{value | currency: 'EUR'}}</div>
                              <div *ngIf="statsItem.type !== 'currency'">{{value | percent: '1.0-2' }}</div>
                              <div [class]='diff >= 0  ? "green-text" : "red-text" '>
                                <span *ngIf="diff > 0">+</span>
                                <ng-container *ngIf="statsItem.type === 'currency'">{{diff | currency: 'EUR'}}</ng-container>
                                <ng-container *ngIf="statsItem.type === 'percentage'">{{diff | percent: '1.0-2'}}</ng-container>
                              </div>
                              <div class='compared'>{{'geo-strategy.comparedToFrance' | translate}}</div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </li>
                    </ul>

                  </div>


                </mat-expansion-panel>

              </mat-accordion>
            </mat-sidenav>
            <mat-sidenav [(opened)]='prospectionMode' mode="side" position="end">
              <div class="card">
                <table class="table striped z-depth-2" *ngIf='selectedFeatures.length > 0'>
                  <thead>
                    <tr>
                      <th>
                        {{'global.nb_address_total' | translate}}
                      </th>
                      <th colspan="2">
                        {{'global.vat-exclude' | translate}}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{{getTotalSelectedPools()}}</th>
                      <th>
                        {{getProspectionPrice() | currency : 'EUR'}}*
                      </th>
                      <th>
                        <button
                          class="btn rounded-button small"
                          (click)='orderCampaign()'
                          [disabled]='getTotalSelectedPools() < 1'
                        >
                          {{'prospection.orderCampaign' | translate}}
                        </button>
                      </th>
                    </tr>
                    <tr *ngIf="currentUser.buyAddresses">
                      <th></th>
                      <th>
                        {{getBuyPrice(getTotalSelectedPools()) | currency : 'EUR'}}*
                      </th>
                      <th>
                        <button
                          class="btn rounded-button small"
                          (click)='orderAddresses()'
                          [disabled]='getTotalSelectedPools() < 1'
                        >
                          {{'prospection.orderAddresses' | translate}}
                        </button>
                      </th>
                    </tr>
                  </tbody>
                </table>

                <table class='table striped' *ngIf='selectedFeatures.length > 0 else selectOnMap'>
                  <thead>
                    <tr>
                      <th>{{'geo-strategy.area' | translate}}</th>
                      <th>{{'geo-strategy.nb_address' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor='let feature of selectedFeatures'>
                      <td>
                        <ng-container>{{feature.properties.nom_zone}}<br/></ng-container>
                        <span class="feature-subtitle">
                          <ng-container *ngIf="feature.properties.cp && feature.properties.cp !== '0'">{{feature.properties.cp}}</ng-container>
                          <ng-container *ngIf="feature.properties.lib_com && feature.properties.lib_com !== feature.properties.nom_zone"> {{feature.properties.lib_com}}</ng-container>
                        </span>
                      </td>
                      <td>{{feature.properties.nb_po_ad}}</td>
                      <td>
                        <button mat-icon-button (click)='highlightAFeature(feature)'>
                          <i class="material-icons">location_on</i>
                        </button>
                        <button mat-icon-button (click)='unselectFeature(feature)'>
                          <i class="material-icons">delete</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #selectOnMap>
                  <div class='card-content'>
                    <p>{{'geo-strategy.select-on-map' | translate}}</p>
                  </div>
                </ng-template>
              </div>
            </mat-sidenav>
            <div class="relative">
              <button mat-icon-button (click)="sidenav.toggle()" id='sidenav-toggle-button'><i
                  class='material-icons'>menu</i></button>
              <mat-slide-toggle *ngIf='map' [(ngModel)]="prospectionMode" (change)='onModeChange()'
                id='prospection-slide-toggle' class='map-overlay'>{{'geo-strategy.prospectionMode' | translate}}
              </mat-slide-toggle>
              <mat-slide-toggle *ngIf='map && showStores' id='stores-slide-toggle' (change)='showAllStores($event)'
                class='map-overlay'>{{'geo-strategy.show-stores' | translate}}</mat-slide-toggle>
              <mgl-map [style]="style" [zoom]="5" [minZoom]='5' (load)="initMap($event)"
                [center]="[2.213749, 46.227638]"></mgl-map>
              <div class='map-overlay' id='popside' #popside></div>
              <div class='map-overlay' id='legend' #legend></div>
            </div>
          </mat-sidenav-container>
        </div>








        <!--<div class="card-action right-align">
          <button class="rounded-button">
              <i class="material-icons right">search</i>
              {{ 'global.search' | translate }}
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>
