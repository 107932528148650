import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef, NgZone } from '@angular/core';
import { DataService } from "../../helpers/data.service";
import { environment } from "../../../environments/environment";
import * as MapboxGL from "mapbox-gl";
import { TranslateService } from '@ngx-translate/core';
import { Campaign } from '../../models/campaign';
import { AddressOrder } from '../../models/address-order';
import * as turf from '@turf/center';
import { CampaignService } from '../../services/campaign.service';
import { AddressesOrderService } from '../../services/addressesOrder.service';
import { CampaignStatus } from '../../utils/campaign-status';
import { AddressesOrderStatus } from '../../utils/addresses-order-status';
import { MatDialog } from '@angular/material/dialog';
import { CampaignNameDialogComponent } from '../../components/campaign-name-dialog/campaign-name-dialog.component';
import { AddressOrderInfoDialogComponent } from '../../components/address-order-info-dialog/address-order-info-dialog.component';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { formatNumber } from '@angular/common';


interface IrisStats {
  id: string,
  type: string,
  minValue: number,
  maxValue: number
}

const percentageStats = {
  type: "percentage",
  minValue: 0,
  maxValue: 100
}


@Component({
  selector: "app-geo-strategy",
  templateUrl: "./geo-strategy.component.html",
  styleUrls: ["./geo-strategy.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class GeoStrategyComponent implements OnInit {
  map: MapboxGL.Map;
  style: string;
  poolRanges: number[][];
  potPoolsRanges: number[][];
  growthRanges: number[][];
  existingColors: string[];
  potentielColors: string[];
  growthColors: string[];
  growthSteps: string[];
  layers: object[];
  inseeStats: IrisStats[];
  poolsStats: IrisStats[];
  address: google.maps.places.PlaceResult;
  currentUser: User;

  mapMarker: MapboxGL.Marker;
  mapPopup: MapboxGL.Popup;
  mapSideNavOpen: boolean = false;
  accordionSteps: number;
  selectedFeatures: any[];
  clickedFeature;
  prospectionMode: boolean;
  showStores: boolean;
  // treeFeatures = [
  //   { name: "Region", children: [] },
  //   { name: "Departement", children: [] },
  //   { name: "Iris", children: [] }
  // ] as FeatureNode[];
  campaign: Campaign;
  //ViewChilds
  @ViewChild("legend", { static: false }) legend: ElementRef;
  @ViewChild("popside", { static: false }) popside: ElementRef;

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private campaignService: CampaignService,
    private addressesOrderService: AddressesOrderService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.selectedFeatures = [];
    this.style = environment.mapbox.geostrat.style;
    this.currentUser = this.dataService.state.user;
    this.layers = [
      {
        name: "layer_region",
        zoom: [4, 6],
        outlineWidth: 3
      },
      {
        name: "layer_dep",
        zoom: [6, 8],
        outlineWidth: 2
      },
      {
        name: "layer_iris",
        zoom: [8, 22],
        outlineWidth: 1
      }
    ];

    // Stats used to filter iris
    this.inseeStats = [
      {
        id: "rev",
        type: "currency",
        minValue: 5000,
        maxValue: 50000
      },
      {
        id: "p_cspp",
      ...percentageStats
      },
      {
        id: "p_mais",
      ...percentageStats
      },
      {
        id: "p_res",
      ...percentageStats
      },
      {
        id: "p_retr",
      ...percentageStats
      },
      {
        id: "p_fam",
      ...percentageStats
      },
    ]

    // Pools stats used to filter iris
    this.poolsStats = [
      {
        id: "nb_pools",
        type: "int",
        minValue: 0,
        maxValue: 500
      },
      {
        id: "nb_po_ad",
        type: "int",
        minValue: 0,
        maxValue: 500
      },
      {
        id: "calc_max_n",
        type: "int",
        minValue: 0,
        maxValue: 500
      }
    ]

    /**
     * color length MUST corespand to each range length !
     */

    //existing pools ranges and colors
    this.poolRanges = [
      [0, 1000, 10000, 25000, 50000, 100000, 150000, 200000],
      [0, 100, 1000, 2000, 4500, 10000, 20000, 50000],
      [0, 10, 50, 100, 500, 750, 1000]
    ];
    this.existingColors = [
      "#bababa",
      "#ffffd4",
      "#fee391",
      "#fec44f",
      "#fe9929",
      "#ec7014",
      "#cc4c02",
      "#8c2d04"
    ];

    //potential pools ranges and colors
    this.potPoolsRanges = [
      [0, 200, 2000, 5000, 10000, 20000],
      [0, 50, 500, 1000, 2000, 5000],
      [0, 10, 25, 50, 100, 250]
    ];
    this.potentielColors = [
      "#bababa",
      "#f7feae",
      "#b7e6a5",
      "#7ccba2",
      "#46aea0",
      "#089099"
    ];

    //pools growth  ranges and colors
    this.growthRanges = [
      [-10, -2, 0, 2, 10],
      [-20, -4, 0, 4, 20],
      [-30, -6, 0, 6, 30]
    ];
    this.growthColors = ['#bdbdbd', '#dfccaa', '#f4ebae', '#f0ec05', '#7daf28'];
    this.translate
      .stream("geo-strategy.growthSteps")
      .subscribe(data => (this.growthSteps = data));
  }

  ngOnInit() {
    setTimeout(() => this.dataService.enableLoader(true));

    if (this.dataService.state.campaign && this.dataService.state.campaign.status) {
      this.campaign = this.dataService.state.campaign;
      this.selectedFeatures = this.campaign.selected_zones || [];
    }
    else {
      this.campaign = {};
    }
    this.showStores = this.dataService.state.user.showStores;
  }

  initMap(map) {
    this.map = map;

    // disable map rotation using right click + drag
    map.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();

    environment.mapbox.geostrat.sources.map(source => {
      this.map.addSource(source.name, {
        type: "vector",
        url: source.url
      });
    });

    //select first option (existing pools);
    this.changeLayer("existing");
    this.filterByZone();

    this.map.setLayoutProperty('piscinistes', 'visibility', 'none');

    if (this.campaign.selected_zones && this.campaign.selected_zones.length > 0) {
      this.prospectionMode = true;
      this.map.setZoom(10);
      this.map.setCenter(this.campaign.selected_zones[0].coords);
      this.campaign.selected_zones.forEach(feature => {
        feature.source = environment.mapbox.geostrat.sources[2].name;
        this.map.setFeatureState({ id: feature.id, source: feature.source, sourceLayer: feature.source }, { hover: true });
      })
    }

    // Pop up piscinistes
    this.map.on('click', 'piscinistes', function (e) {
      const feature = e.features[0];
      var coordinates = feature.geometry["coordinates"].slice();
      const { name, address, phone, website } = feature.properties;
      const description = `
        <b>${name}</b>
        <p>Adresse:&nbsp;${address}</p>
        <p>Tel:&nbsp;${phone}</p>
        ${website ? `<p><a href="${website}" target="_blank">${website}</a></p>` : ''}
      `;
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      new MapboxGL.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'places', function () {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'places', function () {
      map.getCanvas().style.cursor = '';
    });
    this.dataService.enableLoader(false);
  }

  /**
   * Create legend component
   * @param legendTitle
   * @param steps
   * @param colors
   */
  addLegend(legendTitle: string, steps: string[], colors: string[]) {
    this.legend.nativeElement.innerHTML = "";
    let title = document.createElement("div");
    title.innerHTML = legendTitle;
    title.className = "center-align legend-title";

    this.legend.nativeElement.appendChild(title);
    steps.map((step, index) => {
      let color = colors[index];
      let item = document.createElement("div");
      let key = document.createElement("span");
      key.className = "legend-key";
      key.style.backgroundColor = color;

      let value = document.createElement("span");
      value.innerHTML = step;
      item.appendChild(key);
      item.appendChild(value);
      this.legend.nativeElement.appendChild(item);
    });
  }

  /**
   * Transform range array to legend steps
   * @param range number[]
   */
  rangeToLegendSteps(range: number[]) {
    let steps = range.map((step, index) => {
      let res = `${formatNumber(range[index - 1], 'fr')} - ${formatNumber(step, 'fr')}`;
      if (index === range.length - 1) res = `${formatNumber(range[index - 1], 'fr')}+`;
      return res;
    });
    steps.shift();
    return steps;
  }

  /**
   * Function to clean all current layers
   */
  cleanLayers() {
    const layersToRemove = ["layer_region", "layer_dep", "layer_iris", "layer_region-outline", "layer_dep-outline", "layer_iris-outline"]
    layersToRemove.forEach(layer => {
      if(this.map.getLayer(layer)){
        this.map.removeLayer(layer);
      }
    })
  }

  addLayerOutline(name: string, sourceLayer: string, minZoom: number, paint: Object){
    this.map.addLayer(
      {
        id: `${name}-outline`,
        type: "line",
        source: sourceLayer,
        "source-layer": sourceLayer,
        minzoom: minZoom,
        paint: paint
      }
    );
  }

  /**
   * Function to add a layer to map with an existing source
   */
  addLayer(
    name: string,
    sourceLayer: string,
    minZoom: number,
    maxZoom: number,
    paint: Object
  ) {

    this.map.addLayer(
      {
        id: name,
        type: "fill",
        source: sourceLayer,
        "source-layer": sourceLayer,
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: paint
      },
      "poi-label"
    );

    this.map.on("mousemove", name, e => {
      this.showMapPopup(e)
      const feature = e.features[0];

      if (this.prospectionMode) {
        if (e.originalEvent.shiftKey) {
          if (!this.map.getFeatureState({
            source: feature.source,
            sourceLayer: feature.source,
            id: feature.id
          }).hover) {
            this.map.setFeatureState(
              {
                source: feature.source,
                sourceLayer: feature.source,
                id: feature.id
              },
              { hover: true }
            );
            this.selectedFeatures.push(feature);
          }
        }
        else if (e.originalEvent.ctrlKey) {
          this.selectedFeatures = this.selectedFeatures.filter(
            (currentFeature: MapboxGL.MapboxGeoJSONFeature) => feature.id !== currentFeature.id
          );
          this.map.setFeatureState(
            {
              source: feature.source,
              sourceLayer: feature.source,
              id: feature.id
            },
            { hover: false }
          );
        }
      }
      this.cd.detectChanges();
    });
    this.map.on("click", name, e => {
      this.map.getCanvas().style.cursor = "pointer";
      this.clickedFeature = e.features[0].properties;
      if (this.prospectionMode) this.selectFeature(e.features[0]);
      this.accordionSteps = 0;
      this.mapSideNavOpen = true;
      this.cd.detectChanges();
    });
  }

  /**
   * On selectmode enabled, highlight geostrat_iris clicked features
   * @param feature MapboxGL.MapboxGeoJSONFeature
   */
  selectFeature(feature: MapboxGL.MapboxGeoJSONFeature) {
    if (feature.source === environment.mapbox.geostrat.sources[2].name) {
      if (
        this.map.getFeatureState({
          source: feature.source,
          sourceLayer: feature.source,
          id: feature.id
        }).hover
      ) {
        // this.treeFeatures[2].children = this.treeFeatures[2].children.filter(
        //   child =>
        //     child.properties["code_zone"] !== feature.properties.code_zone
        // );
        this.selectedFeatures = this.selectedFeatures.filter(
          (currentFeature: MapboxGL.MapboxGeoJSONFeature) => feature.id !== currentFeature.id
        );
        this.map.setFeatureState(
          {
            source: feature.source,
            sourceLayer: feature.source,
            id: feature.id
          },
          { hover: false }
        );
      } else {
        // this.treeFeatures[2].children.push({
        //   name: feature.properties.nom_zone,
        //   properties: feature.properties
        // });
        this.selectedFeatures.push(feature);
        this.map.setFeatureState(
          {
            source: feature.source,
            sourceLayer: feature.source,
            id: feature.id
          },
          { hover: true }
        );
      }
      // this.treeFeatures = [...this.treeFeatures];
    }
  }

  /**
   * Show map popup
   * @param e
   */
  showMapPopup(
    e: MapboxGL.MapMouseEvent & {
      features?: MapboxGL.MapboxGeoJSONFeature[];
    } & MapboxGL.EventData
  ) {
    this.map.getCanvas().style.cursor = "pointer";
    const feature = e.features[0];
    const props = feature.properties;
    let niv = 0;
    let potentialPools = props.calc_max_n
    if (this.map.getZoom() >= 8) {
      niv = 2;
      potentialPools = Math.max(0, props.calc_pools - props.nb_pools)
    } else if (this.map.getZoom() >= 6) {
      niv = 1;
    }
    const t = _ => this.translate.instant(_);
    const isIris = feature.sourceLayer === environment.mapbox.geostrat.sources[2].name;

    this.popside.nativeElement.innerHTML = `
      <div class='black-text'>
        <h6 class="center-align">
            ${isIris ? (
              `${props["nom_zone"]}${
                props['cp'] && props['cp'] !== '0' ? ` (${props['cp']})` : ''
              }`
            ) : (
              `${props["code_zone"]} - ${props["nom_zone"]}`
            )}
        </h6>
        <p class="center-align">
          <b>${formatNumber(props["nb_pools"], 'fr')}</b> ${t("geo-strategy.existing")}</br>
          <b>${formatNumber(props["nb_po_ad"], 'fr')}</b> ${t("geo-strategy.addressable")}</br>
          <b>${formatNumber(potentialPools, 'fr')}</b> ${t("geo-strategy.potentiel")}</br>
          <b>${this.getGrowthLabel(props["perc_pot"], this.growthRanges[niv])}</b></br>
        </p>
      </div>`;
  }

  /**
   * Get growth label for popup
   * @param value number
   */
  getGrowthLabel(value: number, ranges: number[]) {
    if (value <= ranges[0]) return this.growthSteps[0];
    else if (value > ranges[0] && value <= ranges[1]) return this.growthSteps[1];
    else if (value > ranges[1] && value <= ranges[3]) return this.growthSteps[2];
    else if (value > ranges[3] && value <= ranges[4]) return this.growthSteps[3];
    else value > ranges[4];
    return this.growthSteps[4];
  }

  /**
   * Add layers and filters by mode
   * @param field tileset field name
   * @param ranges  min max of field
   * @param colors colors for min to max
   */
  addDataLayers(colorExpression: (arg: any[]) => any[], ranges: number[][], colors: string[]) {
    let filter;
    this.layers.map(layer => {
      if (this.map.getLayer(layer["name"]))
        filter = this.map.getFilter(layer["name"]);
    });

    this.cleanLayers();
    let colorsByRange = ranges.map(range =>
      range.map((step, index) => [step, colors[index]])
    );
    this.layers.map((layer, index) => {
      const colors: any[] = []
      colorsByRange[index].map(step => {
        step.map(ele => colors.push(ele));
      });

      this.addLayer(
        layer["name"],
        environment.mapbox.geostrat.sources[index].name,
        layer["zoom"][0],
        layer["zoom"][1],
        {
          "fill-outline-color": "#000000",
          "fill-color": colorExpression(colors)
        }
      );
      this.addLayerOutline(
        layer["name"],
        environment.mapbox.geostrat.sources[index].name,
        layer["zoom"][1],
        {
          "line-color": "#000000",
          "line-width": layer["outlineWidth"]
        }
      );
      if (filter) this.map.setFilter(layer["name"], filter);
    });
  }

  /**
   * Event Listener for event from Autocomplete component
   * @param address google.maps.places.PlaceResult
   */
  setAddress(address: google.maps.places.PlaceResult) {
    this.address = address;
    if (address.geometry) {
      let location = address.geometry.location;
      let viewpoint = this.address.geometry.viewport;

      let bounds = [
        {
          lng: viewpoint.getSouthWest().lng(),
          lat: viewpoint.getSouthWest().lat()
        },
        {
          lng: viewpoint.getNorthEast().lng(),
          lat: viewpoint.getNorthEast().lat()
        }
      ];
      this.map.fitBounds(<MapboxGL.LngLatBoundsLike>bounds);
      if (this.mapMarker) this.mapMarker.remove();
      this.mapMarker = new MapboxGL.Marker()
        .setLngLat([location.lng(), location.lat()])
        .addTo(this.map);

    }
  }

  /**
   * Function executed on change radio buttons
   * @param name string
   */

  changeLayer(name: string) {
    switch (name) {
      case "existing":
        this.drawLegend("existing");
        this.addDataLayers(
          colors => [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#99CCFF",
            ["interpolate", ["linear"], ["get", "nb_pools"], ...colors]
          ], this.poolRanges, this.existingColors);
        break;
      case "potentiel":
        this.drawLegend("potentiel");
        //TODO redefine potentials algo output so we can pass a simple field as parameter, instead of a complex query
        this.addDataLayers(
          colors => [
            'step', ['zoom'],
            [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              "#99CCFF",
              ["interpolate", ["linear"], ["get", "calc_max_n"], ...colors]
            ],
            8,
            [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              "#99CCFF",
              ["interpolate", ["linear"], [
                "case", [">", ["get", "calc_pools"], ["get", "nb_pools"]], [
                  "-", ["get", "calc_pools"], ["get", "nb_pools"]
                ], 0
              ], ...colors]
            ]
          ],
          this.potPoolsRanges,
          this.potentielColors
        );
        break;
      case "growth":
        this.drawLegend("growth");
        this.addDataLayers(colors => [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#99CCFF",
          ["interpolate", ["linear"], ["get", "perc_pot"], ...colors]
        ], this.growthRanges, this.growthColors);
        break;

      default:
        this.cleanLayers();
        break;
    }
  }

  /**
   * Draw legend depending on current layer id and zoom level
   * @param name currentLayer id
   */
  drawLegend(name: string) {
    switch (name) {
      case "existing":
        this.addLegend(
          this.translate.instant("geo-strategy.poolsBy.region"),
          this.rangeToLegendSteps(this.poolRanges[0]),
          this.existingColors
        );
        break;
      case "potentiel":
        this.addLegend(
          this.translate.instant("geo-strategy.poolsBy.region"),
          this.rangeToLegendSteps(this.potPoolsRanges[0]),
          this.potentielColors
        );
        break;
      case "growth":
        this.addLegend(
          this.translate.instant("geo-strategy.poolsBy.region"),
          this.growthSteps,
          this.growthColors
        );
        break;
    }

    this.map.on("zoom", e => {
      if (this.map.getZoom() >= 8) {
        switch (name) {
          case "existing":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.iris"),
              this.rangeToLegendSteps(this.poolRanges[2]),
              this.existingColors
            );
            break;
          case "potentiel":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.iris"),
              this.rangeToLegendSteps(this.potPoolsRanges[2]),
              this.potentielColors
            );
            break;
          case "growth":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.iris"),
              this.growthSteps,
              this.growthColors
            );
            break;
        }
      } else if (this.map.getZoom() >= 6) {
        switch (name) {
          case "existing":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.dep"),
              this.rangeToLegendSteps(this.poolRanges[1]),
              this.existingColors
            );
            break;
          case "potentiel":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.dep"),
              this.rangeToLegendSteps(this.potPoolsRanges[1]),
              this.potentielColors
            );
            break;
          case "growth":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.dep"),
              this.growthSteps,
              this.growthColors
            );
            break;
        }
      } else if (this.map.getZoom() >= 4) {
        switch (name) {
          case "existing":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.region"),
              this.rangeToLegendSteps(this.poolRanges[0]),
              this.existingColors
            );
            break;
          case "potentiel":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.region"),
              this.rangeToLegendSteps(this.potPoolsRanges[0]),
              this.potentielColors
            );
            break;
          case "growth":
            this.addLegend(
              this.translate.instant("geo-strategy.poolsBy.region"),
              this.growthSteps,
              this.growthColors
            );
            break;
        }
      }
    });
  }

  /**
   * Filter iris_layer by allowed list of iris to user.
  */
  filterByZone() {
    const { irisToEnable, depsToEnable } = this.dataService.state.user;
    const conditions = [];
    if (irisToEnable.length > 0) {
      conditions.push(['in', 'code_iris', ...irisToEnable]);
    }
    if(depsToEnable && depsToEnable.length > 0){
      conditions.push(['in', 'code_dep', ...depsToEnable]);
    }
    if (conditions.length > 0) {
      //Filter Features to show
      const filters = ['any', ...conditions];
      this.map.setFilter(this.layers[2]["name"], filters);
      this.map.setMinZoom(8);
    }

  }



  /**
   * Listen to filter-ranger change and filter layers by field
   * @param range object
   * @param field string
   */
  onFilter(range: object, stats: IrisStats) {
    this.layers.map(layer => {
      let filters = this.map.getFilter(layer["name"]) || ["all"];
      filters = filters.filter(filtr => filtr[1] !== stats.id);
      const factor = stats.type === "percentage" ? 0.01 : 1;
      if(range["value"] !== stats.minValue){
        filters.push([">=", stats.id, range["value"] * factor]);
      }
      if(range["highValue"] !== stats.maxValue){
        filters.push(["<=", stats.id, range["highValue"] * factor]);
      }
      this.map.setFilter(layer["name"], filters);
    });
  }

  /**
   * Listener for mode changing
   * @param value boolean
   */
  onModeChange() {
    if (!this.prospectionMode) {
      this.selectedFeatures.map((feature: MapboxGL.MapboxGeoJSONFeature) =>
        this.map.setFeatureState(
          {
            source: feature.source,
            sourceLayer: feature.source,
            id: feature.id
          },
          { hover: false }
        )
      );
      this.selectedFeatures = [];
      if (this.mapMarker) this.mapMarker.remove();
    } else {
      if (this.map.getZoom() < 8) this.map.zoomTo(8);
    }
  }

  unselectFeature(feature: MapboxGL.MapboxGeoJSONFeature) {
    this.selectedFeatures = [
      ...this.selectedFeatures.filter((feat: MapboxGL.MapboxGeoJSONFeature) => feat.id !== feature.id)
    ];
    this.map.setFeatureState(
      { source: feature.source, sourceLayer: feature.source, id: feature.id },
      { hover: false }
    );
    if (this.mapMarker) this.mapMarker.remove();
    this.cd.detectChanges();
  }

  getTotalSelectedPools() {
    return this.selectedFeatures
      .map((feature: MapboxGL.MapboxGeoJSONFeature) => feature.properties.nb_po_ad)
      .reduce((a, b) => a + b);
  }

  getProspectionPrice() {
    let price: number = 0,
      flyerPrice: number = 0;

    this.dataService.state.user.pricing.ranges.map(range => {
      if (this.getTotalSelectedPools() >= range.range) {
        price = range.priceHO;
      }
    });

    if (this.currentUser.flyerStep) {
      let defaultPostage = this.currentUser.postage.filter(
        post => post.default === true
      )[0];
      defaultPostage.prices.map(pric => {
        if (this.getTotalSelectedPools() >= pric.range) {
          flyerPrice = pric.price;
        }
      });
    }
    return this.getTotalSelectedPools() * (Number(price) + Number(flyerPrice));
  }

  setCampaign = () => {
    this.campaign.selected_zones = this.selectedFeatures.map(
      (feature: MapboxGL.MapboxGeoJSONFeature) => ({
        id: feature.id,
        properties: {
          code_iris: feature.properties.code_iris,
          nom_zone: feature.properties.nom_zone,
          nb_po_ad: feature.properties.nb_po_ad,
          lib_com: feature.properties.lib_com,
          cp: feature.properties.cp,
        },
        coords: feature['coords'] ? feature['coords'] : turf.default(feature).geometry.coordinates as MapboxGL.LngLatLike,
      })
    );
    this.campaign.status = CampaignStatus.init;
    this.campaign.user = this.dataService.state.user.id
  }

  saveCampaign = async campaignName => {
    this.campaign.name = campaignName;
    this.setCampaign();
    let request;
    if (!this.campaign.id) {
      request = this.campaignService.initByZone(this.campaign);
    } else {
      request = this.campaignService.updateByZone(this.campaign);
    }
    return request.toPromise().then(data => {
      this.campaign.id = data['_id'];
      this.campaign.price = data['price'];
      this.campaign.numberOfAddresses = data['numberOfAddresses'];
      this.dataService.setCampaign(this.campaign)
    }).catch(error => {
      console.error(error)
      throw error
    });
  }

  highlightAFeature(feature: MapboxGL.MapboxGeoJSONFeature) {
    let center: MapboxGL.LngLatLike;
    if (feature['coords']) {
      center = feature['coords'];
    } else {
      center = turf.default(feature).geometry.coordinates;
    }

    this.map.setCenter(center);
    this.map.setZoom(10);
    this.clickedFeature = feature.properties;
    this.cd.detectChanges();
    if (this.mapMarker) this.mapMarker.remove();
    this.mapMarker = new MapboxGL.Marker().setLngLat(center).addTo(this.map);
  }

  navigateToNextPage = () => {
    const { flyerStep } = this.dataService.state.user;
    this.router.navigate([ flyerStep ? '/flyer' : 'recapitulate']);
  }

  save = async (onSaveCallback = null) => {
    const data: any = {};
    if(this.campaign.name) {
      data.name = this.campaign.name;
    }
    const dialogRef = this.dialog.open(CampaignNameDialogComponent, { data });
    const name = await dialogRef.afterClosed().toPromise();
    if(!name) return;
    await this.saveCampaign(name).then(onSaveCallback);
  }

  orderCampaign = async () => {
    this.ngZone.run(() => this.save(this.navigateToNextPage));
  }
  
  saveOrder = async (order: AddressOrder): Promise<any> => {
    this.dataService.enableLoader(true);
    order.status = AddressesOrderStatus.init;
    try{
      const data = await this.addressesOrderService.init(order).toPromise()
      order.id = data['_id'];
      order.price = data['price'];
      order.numberOfAddresses = data['numberOfAddresses'];
      this.dataService.setAddressOrder(order);
      this.dataService.enableLoader(false);
    }catch(error){
      console.error(error)
      this.dataService.enableLoader(false);
      throw error;
    }
  }

  orderAddresses = () => {
    this.ngZone.run(async () => {
      const dialogRef = this.dialog.open(AddressOrderInfoDialogComponent);
      const proceed: boolean = await dialogRef.afterClosed().toPromise();
      if(!proceed) return;
      this.setCampaign();
      const { address, numberOfAddresses, radius, user, price, order, removed_addresses, selected_zones} = this.campaign;
      const addressOrder: AddressOrder = {
        address, numberOfAddresses, radius, user, price, order, removed_addresses, selected_zones
      }
      await this.saveOrder(addressOrder);
      this.router.navigate(['/order-addresses']);
    });
  }

  getBuyPrice = (nbAddresses: number): number => {
    const range = this.currentUser.buyPricing.ranges
      .slice()
      .reverse()
      .find(_ => nbAddresses >= _.range)
    if(!range) throw Error("Invalid buyPricing");
    return range.priceHO * nbAddresses;
  }

  /**
   * Listener on switch that allow to show or hides stores on map
   * @param value
   */
  showAllStores(value: boolean) {
    if (value['checked']) {
      if(!this.showStores) throw Error("Unauthorized");
      this.map.setLayoutProperty('piscinistes', 'visibility', 'visible');
    }
    else
      this.map.setLayoutProperty('piscinistes', 'visibility', 'none');
  }


}

