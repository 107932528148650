import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';


@Component({
  selector: 'filter-slider',
  templateUrl: './filter-slider.component.html',
  styleUrls: ['./filter-slider.component.css']
})
export class FilterSliderComponent implements OnInit {

  @Input() minValue: number;
  @Input() maxValue: number;

  @Output() range = new EventEmitter();


  options:Options;


  constructor() {}

  ngOnInit(){
    this.options = {
        floor: this.minValue,
        ceil: this.maxValue,
        animate: false

    }
  }

  onUserChange(value){
    this.range.emit(value);
  }
}
