import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoLocation } from '../models/geo-location';
import { environment } from '../../environments/environment';
import { AddressOrder } from '../models/address-order';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AddressesOrderService {
  
  constructor(private http: HttpClient) {}

  init(order: AddressOrder){
    return this.http.post(`${environment.baseUrl}/addressOrder/init`, order);
  }

  update(order: AddressOrder){
    return this.http.put(`${environment.baseUrl}/addressOrder/update`, order);
  }

  ordersByUser(userId: string): Observable<AddressOrder[]>{
    return this.http.get<AddressOrder[]>(`${environment.baseUrl}/addressOrder/${userId}`)
  }

  removeAddresses(order: AddressOrder, addresses){
    return this.http.post(`${environment.baseUrl}/addressOrder/remove-addresses`, {order, addresses});
  }
}
