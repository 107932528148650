<div>
    <nav [ngStyle]='!currentUser && {"display": "none"}' >
      <div class="nav-wrapper">
        <div class="col s12">
          <a  
            *ngFor='let breadcrumb of breadcrumbs; let i = index' 
            [routerLink]="breadcrumb.url"
            class="breadcrumb" 
            (click)='clickedRoute(i)'
            >{{breadcrumb.name | translate}}</a>
        </div>
      </div>
    </nav>
</div>