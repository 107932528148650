import { Injectable } from '@angular/core';


import * as S3 from 'aws-sdk/clients/s3';
import { UploadedFile } from '../models/uploaded-file';

@Injectable({
  providedIn: 'root'
})
export class FlyerService {

  uploadToS3(uploadedFile: UploadedFile, s3config: any){
    const bucket = new S3({
      accessKeyId: s3config.accessKeyId,
      secretAccessKey: s3config.accessKeySecret,
      region: s3config.region
    });
    const params = {
      Bucket: s3config.bucket,
      Key: s3config.uploadFolder+uploadedFile.name,
      Body: uploadedFile.file,
      ContentType: uploadedFile.file.type
    }

    return bucket.upload(params);
  }
}
