<Header></Header>
<main>
  <div class="section"></div>
  <router-outlet></router-outlet>
</main>

<div *ngIf='dataService.state.loader' class="main-loader">
  <div class="preloader-wrapper big active">
    <div class="spinner-layer spinner-blue-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>
      <div class="gap-patch">
        <div class="circle"></div>
      </div>
      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</div>
