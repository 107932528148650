// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  baseUrl: 'https://api.poolspot.io/dev',
  apiKey: 'AUvc3IOg6L6Jbag7LgTWW9aiQps1FxSU5DB6N2We',
  gtagId: 'UA-154778753-1',
  mapbox: {
    token: 'pk.eyJ1IjoicGl4c3RhcnQiLCJhIjoiY2syYm5rbTN5MDk2ZjNjbWh2MjBscHQ0aSJ9.3Rr6M-799mmn1IeuTYI-aw',
    prospection: 'mapbox://styles/pixstart/ck2bo58xz00z11cnlsbcg437m',
    geostrat: {
      style: 'mapbox://styles/pixstart/ckgmbbdow0h1219plzrt7h1qi',
      //Do not change order of sources array !
      sources: [
        {
          name: 'geostrat-reg-test',
          url: 'mapbox://pixstart.geostrat-reg-test'
        },
        {
          name: 'geostrat-dep-test',
          url: 'mapbox://pixstart.geostrat-dep-test',
        },
        {
          name: 'geostrat-iris-test',
          url: 'mapbox://pixstart.geostrat-iris-test',
        }
      ]
    }
  },
  paypal_client_id: 'ARY4_DLRJszTFNVD-Q3SsdOEtzFat8Ko7McSGC2wp1w0u1vFJszFi5G2vQHlkcpsnF-mzRTiGvohRCdT'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
