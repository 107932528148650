import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/helpers/data.service';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'Header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.state$
      .pipe(
        map(state => state.user),
        distinctUntilChanged()
      )
      .subscribe(
        user => this.currentUser = user
      );
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  navigateTo(route: string){
    this.router.navigate([route]);
  }
}
