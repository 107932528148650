// dep_data was made using data from https://france-geojson.gregoiredavid.fr/, with the following conversion:
// const reducePrecision = (_) => {
//   const factor = Math.pow(10, 4);
//   return Math.round(_ * factor) / factor;
// };

// const getBBox = (feat) => {
//   let coordinates = feat.geometry.coordinates.flat();
//   if (feat.geometry.type === "MultiPolygon") {
//     coordinates = coordinates.flat();
//   }
//   const allLngs = coordinates.map((_) => _[0]);
//   const allLats = coordinates.map((_) => _[1]);
//   return [
//     Math.min(...allLngs),
//     Math.min(...allLats),
//     Math.max(...allLngs),
//     Math.max(...allLats),
//   ].map(reducePrecision);
// };

// const dep_data = departements_geojson.features.map((feat) => {
//   const bbox = getBBox(feat);
//   const { code, nom } = feat.properties;
//   return [code, nom, bbox];
// });

// this format is intended to be small once bundled.
const raw_data: [string, string, number[]][] = [
  ["01", "Ain", [4.7291, 45.6112, 6.1697, 46.5172]],
  ["02", "Aisne", [2.9625, 48.8378, 4.2541, 50.0693]],
  ["03", "Allier", [2.2804, 45.9307, 4.0056, 46.8039]],
  ["04", "Alpes-de-Haute-Provence", [5.498, 43.6683, 6.9668, 44.6595]],
  ["05", "Hautes-Alpes", [5.4185, 44.1865, 7.0771, 45.1268]],
  ["06", "Alpes-Maritimes", [6.6364, 43.4801, 7.7169, 44.3611]],
  ["07", "Ardèche", [3.8615, 44.2648, 4.8866, 45.3657]],
  ["08", "Ardennes", [4.0253, 49.2285, 5.3935, 50.1683]],
  ["09", "Ariège", [0.8261, 42.5724, 2.1759, 43.3156]],
  ["10", "Aube", [3.3884, 47.9241, 4.8632, 48.7167]],
  ["11", "Aude", [1.6884, 42.6489, 3.2406, 43.4599]],
  ["12", "Aveyron", [1.8396, 43.6921, 3.4508, 44.9412]],
  ["13", "Bouches-du-Rhône", [4.2303, 43.1625, 5.8132, 43.9241]],
  ["14", "Calvados", [-1.1595, 48.7522, 0.4463, 49.4299]],
  ["15", "Cantal", [2.0629, 44.6155, 3.3699, 45.4807]],
  ["16", "Charente", [-0.4618, 45.1916, 0.9456, 46.1396]],
  ["17", "Charente-Maritime", [-1.5615, 45.0888, 0.0058, 46.3711]],
  ["18", "Cher", [1.7746, 46.4204, 3.0793, 47.629]],
  ["19", "Corrèze", [1.2271, 44.9237, 2.5284, 45.7639]],
  ["21", "Côte-d'Or", [4.0661, 46.9009, 5.5185, 48.0302]],
  ["22", "Côtes-d'Armor", [-3.6637, 48.0355, -1.909, 48.8674]],
  ["23", "Creuse", [1.3749, 45.664, 2.6108, 46.4548]],
  ["24", "Dordogne", [-0.042, 44.5713, 1.4483, 45.7146]],
  ["25", "Doubs", [5.6987, 46.554, 7.0622, 47.5799]],
  ["26", "Drôme", [4.6478, 44.1157, 5.8295, 45.344]],
  ["27", "Eure", [0.2972, 48.6665, 1.8027, 49.4851]],
  ["28", "Eure-et-Loir", [0.7602, 47.9539, 1.9941, 48.9411]],
  ["29", "Finistère", [-5.138, 47.7626, -3.3881, 48.7523]],
  ["2A", "Corse-du-Sud", [8.5401, 41.3622, 9.4073, 42.3814]],
  ["2B", "Haute-Corse", [8.5734, 41.8321, 9.5592, 43.0117]],
  ["30", "Gard", [3.2628, 43.4602, 4.8456, 44.4598]],
  ["31", "Haute-Garonne", [0.442, 42.6899, 2.0479, 43.9202]],
  ["32", "Gers", [-0.2821, 43.3109, 1.2013, 44.0782]],
  ["33", "Gironde", [-1.2617, 44.1938, 0.3151, 45.5747]],
  ["34", "Hérault", [2.54, 43.2128, 4.1944, 43.9695]],
  ["35", "Ille-et-Vilaine", [-2.2891, 47.6314, -1.0169, 48.7049]],
  ["36", "Indre", [0.8675, 46.3472, 2.2044, 47.2768]],
  ["37", "Indre-et-Loire", [0.0533, 46.7371, 1.3654, 47.7093]],
  ["38", "Isère", [4.7441, 44.6961, 6.3588, 45.8833]],
  ["39", "Jura", [5.2549, 46.2607, 6.2033, 47.3055]],
  ["40", "Landes", [-1.5249, 43.4879, 0.1367, 44.5322]],
  ["41", "Loir-et-Cher", [0.5805, 47.1862, 2.2479, 48.1325]],
  ["42", "Loire", [3.6907, 45.2322, 4.7605, 46.2759]],
  ["43", "Haute-Loire", [3.0823, 44.7439, 4.4896, 45.4276]],
  ["44", "Loire-Atlantique", [-2.5589, 46.8601, -0.9464, 47.8336]],
  ["45", "Loiret", [1.513, 47.483, 3.1284, 48.3446]],
  ["46", "Lot", [0.9818, 44.204, 2.2109, 45.0463]],
  ["47", "Lot-et-Garonne", [-0.1407, 43.9739, 1.0779, 44.7644]],
  ["48", "Lozère", [2.9817, 44.1138, 3.9982, 44.9714]],
  ["49", "Maine-et-Loire", [-1.3542, 46.9694, 0.2345, 47.81]],
  ["50", "Manche", [-1.9473, 48.4583, -0.7373, 49.7256]],
  ["51", "Marne", [3.3987, 48.5161, 5.0379, 49.4062]],
  ["52", "Haute-Marne", [4.6268, 47.577, 5.8909, 48.6887]],
  ["53", "Mayenne", [-1.2382, 47.7334, -0.0499, 48.568]],
  ["54", "Meurthe-et-Moselle", [5.4299, 48.3499, 7.1232, 49.5626]],
  ["55", "Meuse", [4.8886, 48.4107, 5.8542, 49.6171]],
  ["56", "Morbihan", [-3.7321, 47.2831, -2.0358, 48.2109]],
  ["57", "Moselle", [5.8934, 48.5269, 7.6353, 49.51]],
  ["58", "Nièvre", [2.8452, 46.6518, 4.2307, 47.588]],
  ["59", "Nord", [2.0677, 49.9692, 4.228, 51.0885]],
  ["60", "Oise", [1.6896, 49.0605, 3.1626, 49.7583]],
  ["61", "Orne", [-0.8604, 48.1816, 0.9763, 48.9726]],
  ["62", "Pas-de-Calais", [1.5578, 50.021, 3.1884, 51.0065]],
  ["63", "Puy-de-Dôme", [2.388, 45.2871, 3.9844, 46.2555]],
  ["64", "Pyrénées-Atlantiques", [-1.7909, 42.7775, 0.0263, 43.5964]],
  ["65", "Hautes-Pyrénées", [-0.3271, 42.6749, 0.6455, 43.6093]],
  ["66", "Pyrénées-Orientales", [1.7256, 42.3336, 3.1748, 42.9183]],
  ["67", "Bas-Rhin", [6.9404, 48.1204, 8.2304, 49.0779]],
  ["68", "Haut-Rhin", [6.8428, 47.4222, 7.6221, 48.3105]],
  ["69", "Rhône", [4.2435, 45.455, 5.1592, 46.304]],
  ["70", "Haute-Saône", [5.3728, 47.2531, 6.8235, 48.0237]],
  ["71", "Saône-et-Loire", [3.6226, 46.1569, 5.4623, 47.1554]],
  ["72", "Sarthe", [-0.4479, 47.5691, 0.9138, 48.483]],
  ["73", "Savoie", [5.623, 45.0518, 7.1843, 45.9385]],
  ["74", "Haute-Savoie", [5.8074, 45.6822, 7.0439, 46.4081]],
  ["75", "Paris", [2.2242, 48.8163, 2.4676, 48.902]],
  ["76", "Seine-Maritime", [0.0656, 49.2523, 1.7902, 50.0709]],
  ["77", "Seine-et-Marne", [2.3932, 48.1222, 3.5556, 49.1176]],
  ["78", "Yvelines", [1.4473, 48.4401, 2.2266, 49.083]],
  ["79", "Deux-Sèvres", [-0.892, 45.9697, 0.2204, 47.1083]],
  ["80", "Somme", [1.3797, 49.5718, 3.203, 50.3663]],
  ["81", "Tarn", [1.544, 43.3835, 2.9355, 44.2008]],
  ["82", "Tarn-et-Garonne", [0.7381, 43.7708, 1.9964, 44.3933]],
  ["83", "Var", [5.656, 42.982, 6.9337, 43.8068]],
  ["84", "Vaucluse", [4.6492, 43.6587, 5.7573, 44.4314]],
  ["85", "Vendée", [-2.3988, 46.2666, -0.5378, 47.0839]],
  ["86", "Vienne", [-0.1021, 46.049, 1.2127, 47.1758]],
  ["87", "Haute-Vienne", [0.6297, 45.4374, 1.9094, 46.4015]],
  ["88", "Vosges", [5.3945, 47.8131, 7.1983, 48.5136]],
  ["89", "Yonne", [2.8488, 47.3128, 4.3403, 48.3997]],
  ["90", "Territoire de Belfort", [6.7576, 47.4334, 7.1398, 47.8248]],
  ["91", "Essonne", [1.9149, 48.2847, 2.5854, 48.7761]],
  ["92", "Hauts-de-Seine", [2.1459, 48.7295, 2.3364, 48.951]],
  ["93", "Seine-Saint-Denis", [2.2883, 48.8074, 2.6026, 49.0124]],
  ["94", "Val-de-Marne", [2.3102, 48.6883, 2.6137, 48.8614]],
  ["95", "Val-d'Oise", [1.6088, 48.9087, 2.5905, 49.2322]],
];

export interface Departement {
  name: string;
  code: string;
  bbox: number[][];
}

const depData: Departement[] = raw_data.map(([code, name, bbox]) => {
  const sw = bbox.slice(0, 2);
  const ne = bbox.slice(2, 4);
  return {
    name,
    code,
    bbox: [sw, ne],
  };
});

export default depData;
