<div class="container">
    <div class="row section">
        <h4>{{"home.campaign-list" | translate}}</h4>
    </div>
    <div class="card black-text">
        <div class="card-content">
            <table class='table' *ngIf='campaigns.length > 0 else emptyACampaigns'>
                <thead>
                    <tr>
                        <th>{{'campaign-list.name' | translate}}</th>
                        <th>{{'campaign-list.created-at' | translate}}</th>
                        <th>{{'campaign-list.numberOfAddresses' | translate}}</th>
                        <th *ngIf='currentUser.type == "Affiliate" &&currentUser.headOffice.makeReduction'>
                            {{'campaign-list.offeredAddresses' | translate}}</th>
                        <th>Status</th>
                        <th>{{'campaign-list.address' | translate}}</th>
                        <th>{{'campaign-list.price' | translate}}</th>
                        <th>{{'campaign-list.execution-date' | translate}}</th>
                        <th>{{'campaign-list.status.title' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let campaign of campaigns'>
                        <th>{{ campaign.name }}</th>
                        <th>
                            <span *ngIf='campaign.order'>
                                <!-- We're using non-breakable hyphens in dates -->
                                {{ campaign.updated_at | date : 'dd‑MM‑yyyy'  }}
                            </span>
                        </th>
                        <th>{{ campaign.numberOfAddresses }}</th>
                        <th *ngIf='currentUser.type == "Affiliate" &&currentUser.headOffice.makeReduction'>
                            <span *ngIf='campaign.order'>
                                {{ campaign.order.offeredAddresses || 0 | currency: 'EUR'}}
                            </span>
                        </th>
                        <th>
                            <span
                                *ngIf="campaign.status == 'initialized' || campaign.status == 'flyerStep' else orderInProgress">
                                {{'campaign-list.status.init' | translate}}
                            </span>
                            <ng-template #orderInProgress>
                                <span *ngIf="campaign.status == 'waitingForHOConfirmation' else canceled">
                                    {{'campaign-list.status.waiting-confirm' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #canceled>
                                <span *ngIf="campaign.status == 'deniedByHO'  else ordered">
                                    {{'campaign-list.status.canceled' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #ordered>
                                <span
                                    *ngIf="campaign.status == 'ordered' || campaign.status == 'payed'|| campaign.status == 'confirmedByHO' else requested">
                                    {{'campaign-list.status.ordered' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #requested>
                                <span *ngIf="campaign.status == 'requestForPrinting' else dispatched">
                                    {{'campaign-list.status.printing' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #dispatched>
                                <span *ngIf="campaign.status == 'dispatched' else received">
                                    {{'campaign-list.status.dispatched' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #received>
                                <span *ngIf="campaign.status == 'received'">
                                    {{'campaign-list.status.received' | translate}}
                                </span>
                            </ng-template>

                        </th>
                        <th>
                            <span *ngIf='campaign.address else showCommunes'>
                                {{ campaign.address.full_address }}
                            </span>
                            <ng-template #showCommunes>
                                <span class='badge-label' *ngFor='let com of campaign.selected_zones'>
                                    <ng-container>{{com.properties.nom_zone}}</ng-container>
                                    <ng-container *ngIf="com.properties.lib_com && com.properties.lib_com !== com.properties.nom_zone">, {{com.properties.lib_com}}</ng-container>
                                    <ng-container *ngIf="com.properties.cp && com.properties.cp !== '0'"> ({{com.properties.cp}})</ng-container>
                                </span>
                            </ng-template>
                        </th>
                        <th>
                            <span *ngIf='campaign.order'>
                                {{ campaign.order.HTPrice | currency: 'EUR' }}
                            </span>
                        </th>
                        <th>
                            <span *ngIf='campaign.order'>
                                <!-- We're using non-breakable hyphens in dates -->
                                {{ campaign.order.date | date : 'dd‑MM‑yyyy'  }}
                            </span>
                        </th>

                        <th>
                            <ng-container *ngIf="campaign.status === 'initialized' || campaign.status === 'flyerStep'; else campaignIsOrdered">
                                <button mat-flat-button color="primary" (click)='complete(campaign)'>
                                    {{'campaign-list.complete' | translate}}
                                </button>
                            </ng-container>
                            <ng-template #campaignIsOrdered>
                                <button mat-flat-button color="primary" (click)='replay(campaign)'>
                                    {{'campaign-list.replay' | translate}}
                                </button>
                            </ng-template>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ng-template #emptyACampaigns>
                {{'campaign-list.empty-message' | translate}}
            </ng-template>
        </div>
    </div>
</div>
