<div class="input-field">
    <input
    class="input"
    type="text"
    [(ngModel)]="autocompleteInput"
    #addresstext
    id='adress'
    placeholder=""
    />
    <label for="address">{{'global.address' | translate}}</label>
</div>
