<div class="container">
    <div class="row section">
        <h4>{{"address-order-list.summary" | translate}}</h4>
    </div>
    <div class="card black-text">
        <div class="card-content">
            <table class='table' *ngIf='orders.length > 0 else noOrder'>
                <thead>
                    <tr>
                        <th>{{'address-order-list.id' | translate}}</th>
                        <th>{{'campaign-list.created-at' | translate}}</th>
                        <th>{{'campaign-list.numberOfAddresses' | translate}}</th>
                        <th>Status</th>
                        <th>{{'campaign-list.address' | translate}}</th>
                        <th>{{'campaign-list.price' | translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let order of orders'>
                        <th>{{ order.id }}</th>
                        <th>
                            <span *ngIf='order.order'>
                                <!-- We're using non-breakable hyphens in dates -->
                                {{ order.created_at | date : 'dd‑MM‑yyyy'  }}
                            </span>
                        </th>
                        <th>{{ order.numberOfAddresses }}</th>
                        <th>
                            <span
                                *ngIf="order.status == 'initialized' || order.status == 'flyerStep' else orderInProgress">
                                {{'campaign-list.status.init' | translate}}
                            </span>
                            <ng-template #orderInProgress>
                                <span *ngIf="order.status == 'waitingForHOConfirmation' else canceled">
                                    {{'campaign-list.status.waiting-confirm' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #canceled>
                                <span *ngIf="order.status == 'deniedByHO'  else ordered">
                                    {{'campaign-list.status.canceled' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #ordered>
                                <span
                                    *ngIf="order.status == 'ordered' || order.status == 'payed'|| order.status == 'confirmedByHO' else received">
                                    {{'campaign-list.status.ordered' | translate}}
                                </span>
                            </ng-template>
                            <ng-template #received>
                                <span *ngIf="order.status == 'received'">
                                    {{'campaign-list.status.received' | translate}}
                                </span>
                            </ng-template>

                        </th>
                        <th>
                            <span *ngIf='order.address else showCommunes'>
                                {{ order.address.full_address }}
                            </span>
                            <ng-template #showCommunes>
                                <span class='badge-label' *ngFor='let com of order.selected_zones'>
                                    <ng-container>{{com.properties.nom_zone}}</ng-container>
                                    <ng-container *ngIf="com.properties.lib_com && com.properties.lib_com !== com.properties.nom_zone">, {{com.properties.lib_com}}</ng-container>
                                    <ng-container *ngIf="com.properties.cp && com.properties.cp !== '0'"> ({{com.properties.cp}})</ng-container>
                                </span>
                            </ng-template>
                        </th>
                        <th>
                            <span *ngIf='order.order'>
                                {{ order.order.HTPrice | currency: 'EUR' }}
                            </span>
                        </th>
                        <th>
                            <span *ngIf='order.order'>
                                <!-- We're using non-breakable hyphens in dates -->
                                {{ order.order.date | date : 'dd‑MM‑yyyy'  }}
                            </span>
                        </th>

                        <th>
                            <ng-container *ngIf="order.status === 'initialized'">
                                <button mat-flat-button color="primary" (click)='complete(order)'>
                                    {{'campaign-list.complete' | translate}}
                                </button>
                            </ng-container>
                        </th>
                    </tr>
                </tbody>
            </table>
            <ng-template #noOrder>
                {{'address-order-list.noOrder' | translate}}
            </ng-template>
        </div>
    </div>
</div>
