import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { User } from '../../models/user';
import { Country } from 'src/app/models/country';
import { Price } from 'src/app/models/price';
import { DataService } from '../../helpers/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  hasToken: boolean;
  loading: boolean;
  submitted: boolean;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService,
    private snackBar: MatSnackBar,
    private dataService: DataService
  ) { }

  ngOnInit() {
    if(this.dataService.state.user)
    {
      this.authService.logout();
      location.reload();
    }
    if(this.authService.getToken()) {
      //user has a saved token
      this.hasToken = true;
      this.authService.getUserData()
        .pipe(first())
        .subscribe(this.onUserData, this.onRequestError)
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onRequestError = error => {
    this.loading = false;
    this.hasToken = false;
    let message;
    if(error === 'Unauthorized') {
      message = this.translate.instant('login.errors.expired');
    } else if(error === 'Not Found'){
      message = this.translate.instant('login.errors.not-found');
    } else if (error === 'Gone'){
      message = this.translate.instant('login.errors.disabled');
    } else {
      message = this.translate.instant('errors.unknown');
    }
    this.snackBar.open(message, this.translate.instant('global.close'), {
      duration: 2000
    })
  }

  onUserData = data => {
    let user: User;
    if(!data.headOffice){
       user = {
        id: data.id,
        username: data.username,
        headOffice: data.headOffice,
        subscription: data.subscription,
        flyerStep: data.flyerStep,
        makePayment: data.makePayment,
        makeReduction: data.makeReduction,
        countries: (data.countries as Country[]),
        pricing: (data.pricing as Price),
        postage: data.postage,
        reduction: data.reduction,
        type: data.type,
        irisToEnable: data.irisToEnable,
        depsToEnable: data.depsToEnable,
        accessDataVis: data.accessDataVis,
        accessElm: data.accessElm,
        elmPricing: data.elmPricing,
        buyAddresses: data.buyAddresses,
        buyPricing: data.buyPricing,
        showStores: data.showStores,
        logoKey: data.logoKey,
        s3config: data.s3config
      };
    }
    else {
      user = {
        id: data.id,
        username: data.username,
        headOffice: data.headOffice,
        subscription: data.headOffice.subscription,
        flyerStep: data.headOffice.flyerStep,
        makePayment: data.makePayment,
        countries: (data.headOffice.countries as Country[]),
        pricing: (data.headOffice.pricing as Price),
        postage: data.postage,
        reduction: data.reduction,
        type: data.type,
        irisToEnable: data.irisToEnable,
        depsToEnable: data.depsToEnable,
        accessDataVis: data.accessDataVis,
        accessElm: data.accessElm,
        elmPricing: data.elmPricing,
        buyPricing: data.buyPricing,
        buyAddresses: data.buyAddresses,
        showStores: data.showStores,
        logoKey: data.logoKey,
        s3config: data.s3config
      };
    }
    this.dataService.setUser(user);
    this.router.navigate([this.returnUrl])
  }

  onSubmit(){
    this.submitted = true;
    if(this.loginForm.invalid)
      return;
      this.loading = true;
      this.authService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(this.onUserData, this.onRequestError)
  }
}


