import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/helpers/data.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-headoffice-administration',
  templateUrl: './headoffice-administration.component.html',
  styleUrls: ['./headoffice-administration.component.css']
})
export class HeadofficeAdministrationComponent implements OnInit {
  currentUser: User

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.currentUser = this.dataService.state.user;
  }

}
