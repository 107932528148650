import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { DataService } from '../../helpers/data.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  
  oldPassword: string;
  password: string;
  confirmPassword: string;
  passwordValid: boolean;
  wrongOldPassword: boolean;
  constructor(
    public translate: TranslateService, 
    private userService: UserService, 
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private router: Router
    ) { }

  ngOnInit() {
  }


  onStrengthChanged(event){
    if(event === 100){
      return this.passwordValid = true;
    }
    return this.passwordValid = false;
  }

  changePassword(){
    this.dataService.enableLoader(true);
    this.userService
      .changePassword(this.dataService.state.user.id , this.oldPassword, this.password)
      .subscribe(
        data => {
          if(data['wrongPassword']){
            this.wrongOldPassword = true;
            this.oldPassword = '';
            this.snackBar.open('Error please check your old password', this.translate.instant('global.close'), {
              duration: 5000
            })
          }
          else {
            this.snackBar.open('password successfully update', this.translate.instant('global.close'), {
              duration: 3000
            })
            this.router.navigate(['/']);
          }
          this.dataService.enableLoader(false);
        },
        err => {
          this.dataService.enableLoader(false);
          this.snackBar.open('An error has occured please refresh your page', this.translate.instant('global.close'), {
            duration: 5000
          })
        }
      )
  }
}

