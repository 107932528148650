import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Affiliate } from '../models/affiliate';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {

  constructor(private http: HttpClient) { }

  /**
   * Get all affiliate of current headoffice
   * @param userId string
   */
  getAffiliate(userId: string): Observable<Affiliate[]>{
    return  this.http.get<Affiliate[]>(`${environment.baseUrl}/user/affiliate/${userId}`)
  }


  /**
   * Check if username exists
   */

   checkUsername(username: string): Observable<Boolean> {
     return this.http.post<Boolean>(`${environment.baseUrl}/user/check`, {user: username});
   }

   /**
    * Add a new affiliate associated to the current headOffice
    * @param affiliate
    */
   addAffiliate(affiliate){
    return this.http.post(`${environment.baseUrl}/user/affiliate`, affiliate);
   }
  /**
  * Update an existing affiliate
   */
   updateAffiliate(affiliate){
     return this.http.put(`${environment.baseUrl}/user/affiliate`, affiliate)
   }

   /**
    * Disable or enable an affiliate
    */
   changeAffiliateStatus(affiliate: Affiliate){
     let data = {user: affiliate.id, ho: affiliate.headOffice.id, status: !affiliate.enabled };
     return this.http.put(`${environment.baseUrl}/user/affiliate/status`, data);
   }



   editReduction(affiliate: Affiliate){
    let data = {user: affiliate.id, ho: affiliate.headOffice.id, reduction: affiliate.reduction };
    return this.http.put(`${environment.baseUrl}/user/affiliate/reduction`, data);
   }
}
