import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Elm } from "../models/elm";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as S3 from "aws-sdk/clients/s3";

@Injectable({
  providedIn: "root",
})
export class ElmService {
  bucket: S3;
  uploadFolder: string;

  constructor(private http: HttpClient) {}

  setS3config(s3config) {
    this.bucket = new S3({
      accessKeyId: s3config.accessKeyId,
      secretAccessKey: s3config.accessKeySecret,
      region: s3config.region,
      params: {
        Bucket: s3config.bucket,
      },
    });
    this.uploadFolder = s3config.uploadFolder;
  }

  getAll(): Observable<Elm[]> {
    return this.http.get<Elm[]>(`${environment.baseUrl}/elm`).pipe(
      map((elms) =>
        elms.map((elm) => ({
          ...elm,
          date: new Date(elm.date),
        }))
      )
    );
  }

  createElm(elm: Elm) {
    return this.http.put<any>(`${environment.baseUrl}/elm`, {
      ...elm,
      date: elm.date.toISOString(),
    });
  }

  addFileKey(elmId: String, fileKey: String) {
    return this.http.post(`${environment.baseUrl}/elm/${elmId}/fileKey`, {
      fileKey,
    });
  }

  async putElmFile(elm: Elm, elmFile: any) {
    const fileName = `ELM-${elm.dep}-${new Date().toISOString()}`;
    const key = `${this.uploadFolder}elms/${fileName}`;
    await this.bucket
      .putObject({
        Key: key,
        Body: elmFile,
        ContentType:
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      } as S3.PutObjectRequest)
      .promise();
    await this.addFileKey(elm.id, key).toPromise();
  }

  async getElmFile(elm: Elm) {
    const { Body } = await this.bucket
      .getObject({ Key: elm.fileKey } as S3.GetObjectRequest)
      .promise();
    return Body;
  }
}
