<div class='center-align' *ngIf="!hasToken">
        <div class="section"></div>
        <pitch></pitch>
            <div class="section"></div>

            <div class="container row">
              <div class="card row" style="display: inline-block; padding: 0px 38px 0px 48px; border: 1px solid #EEE;">

                <form [formGroup]="loginForm" class="col s12" method="post" (ngSubmit)="onSubmit()">
                  <div class='row'>
                    <div class='col s12'>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='input-field col s12'>
                      <input class='validate' type='text' name='username' id='username' formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" required/>
                      <label for='username'>{{'login.username' | translate}}</label>
                    </div>
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.password.errors.required ">{{'login.errors.username' | translate}}</div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='input-field col s12'>
                      <input class='validate' type='password' name='password' id='password' formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required/>
                      <label for='password'>{{'login.password' | translate}}</label>
                    </div>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback red-text">
                        <div *ngIf="f.password.errors.required ">{{'login.errors.password' | translate}}</div>
                    </div>
                  </div>

                  <br />

                    <div class='row'>
                      <button [disabled]="loading" class="col s12 btn btn-large waves-effect" style='background-color: #102849'>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{'login.login-btn' | translate}}
                      </button>
                    </div>
                </form>
              </div>
            </div>
            <a [routerLink]="'/contact'" class='white-text hover-pointer'>{{'login.create-account' | translate}}</a>
          <div class="section"></div>
          <div class="section"></div>
</div>
