<div class="container">
    <h3 class='page-title'>{{'recapitulate.summary' | translate}}</h3>

    <div class="row">
        <table *ngIf='addressOrder.address' class='white-text table-striped  no-collapse'>
            <tbody>
                <tr>
                    <td colspan="2">{{'global.address' | translate}}: {{addressOrder.address.full_address}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <table class="white-text table-striped  no-collapse">
            <tbody>
                <tr>
                    <td>{{'recapitulate.nb-of-adr' | translate}}</td>
                    <td>{{addressOrder.numberOfAddresses}}</td>
                </tr>
                <!--<tr>
                    <td>Postage price to pay</td>
                    <td>{{ order.postagePrice | currency: 'EUR'}}</td>
                </tr>-->
                <tr *ngIf='order.offeredAddresses'>
                    <td>{{'recapitulate.offered-adr' | translate}}</td>
                    <td>{{order.offeredAddresses | currency: 'EUR'}}</td>
                </tr>
                <tr>
                  <td>{{'recapitulate.removed-adr' | translate}}</td>
                  <td>
                    <ng-container *ngIf='removedAddresses > 0'>{{removedAddresses}}</ng-container>
                    <ng-container *ngIf='removedAddresses === 0'>{{"recapitulate.no-address" | translate}}</ng-container>
                    &nbsp;<div class='btn-small poolspot-blue edit-button' (click)='removeAddresses()'>{{'global.edit' | translate}}</div>
                  </td>
                </tr>
                <tr>
                    <td>{{'recapitulate.price-excluding-vat' | translate}}</td>
                    <td>{{order.HTPrice | currency: 'EUR'}}</td>
                </tr>
                <tr>
                    <td>{{'recapitulate.vat' | translate}}</td>
                    <td>{{order.taxes | currency: 'EUR'}}</td>
                </tr>
                <tr>
                    <td><h4>{{'recapitulate.final-price' | translate}}</h4></td>
                    <td><h4>{{order.totalPrice | currency: 'EUR'}}</h4></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row col s12 center-align">
        <ngx-paypal *ngIf='(user.makePayment && order.totalPrice > 0) else orderButton'  [config]="payPalConfig"></ngx-paypal>
    </div>
</div>


<ng-template #orderButton>
    <button class="rounded-button col s10 offset-s1" (click)='handleOrder()'>{{'recapitulate.order' | translate}}</button>
</ng-template>


<ng-template #modalInfo>

        <h2 mat-dialog-title>{{'address-order.modal.title' | translate}}</h2>
        <mat-dialog-content class="mat-typography">
          <h3>{{'address-order.modal.order' | translate}}: {{addressOrder.id}}</h3>
          <p [translate]='"address-order.modal.content"' [translateParams]="{id: addressOrder.id}">
          </p>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>{{'global.close' | translate}}</button>
        </mat-dialog-actions>

</ng-template>

