const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover4.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addText("Source de données", {
    x: 4.18,
    y: 0.14,
    w: 5.32,
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "9BCF63",
  });

  slide.addText(
    [
      {
        text: "Données Socio-économiques",
        options: { bold: true, breakLine: true },
      },
      {
        text: "Les données sociodémographiques utilisées dans cet état local de marché sont les données légales les plus récentes disponibles, datées de l’année 2020, publié sur le site de l’INSEE.",
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      { text: "Données Piscines", options: { bold: true, breakLine: true } },
      {
        text: "Les données piscines sont issues des algorithmes de détection par images satellites de Pixstart.",
        options: { breakLine: true },
      },
      {
        text: "Les données de croissances sont issues des algorithmes de prédiction de Pixstart. Ils sont basés sur un ensemble de paramètres tels que les informations socio-économiques, la météo et le nombre de piscines déjà existantes. Les termes “saturation forte”, “saturé”, “croissance” et “croissance forte” ne concernent que le marché de la construction et non le marché de la maintenance.",
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      {
        text: "Données sur la concurrence",
        options: { bold: true, breakLine: true },
      },
      {
        text: "Les données de la concurrence ont été recensées dans l'annuaire (pages jaunes), dans les rubriques « Construction et entretien de piscines» complétées par le site ",
      },
      {
        text: "https://www.guide-piscine.fr/",
        options: {
          hyperlink: { url: "https://www.guide-piscine.fr/" },
          color: "9BCF63",
        },
      },
      {
        text: ".",
        options: { breakLine: true },
      },
    ],
    {
      x: 4.18,
      y: 1.39,
      w: 5.69,
      h: 4.07,
      fontFace: "Pontano Sans",
      fontSize: 12,
      color: "484F56",
      valign: "top",
      paraSpaceAfter: 3,
    }
  );
};

export default addSlide;
