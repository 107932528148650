import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CampaignDialog } from '../../models/campaign-dialog';
import { TranslateService } from '@ngx-translate/core';





@Component({
  selector: "app-campaign-name-dialog",
  templateUrl: "./campaign-name-dialog.component.html",
  styleUrls: ["./campaign-name-dialog.component.css"]
})
export class CampaignNameDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CampaignNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CampaignDialog,
    public translate: TranslateService
  ) {}

  ngOnInit() {}
}
