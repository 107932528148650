<div class="container">
    <div class="row section">
        <h4>{{"home.manage-affiliate" | translate}}</h4>
        <div class="col s12 right-align">
            <button mat-flat-button color='primary' (click)="navigateTo('affiliate/create')"><i class="material-icons">add</i></button>
        </div>
    </div>
    <div class="card black-text">
        <div class="card-content">
            <table class='table' *ngIf='affiliates else emptyAffiliate'>
                <thead>
                    <tr>
                        <th>{{'affiliate.form.input.username' | translate}}</th>
                        <th>{{'affiliate.form.input.email' | translate}}</th>
                        <th>{{'affiliate.form.input.company' | translate}}</th>
                        <th>{{'affiliate.form.input.phone' | translate}}</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let affiliate of affiliates'>
                            <td>{{affiliate.username}}</td>
                            <td>{{affiliate.email}}</td>
                            <td>{{affiliate.company}}</td>
                            <td>{{affiliate.phone}}</td>
                            <td class='group-buttons'>
                                <button mat-flat-button color="primary" (click)="navigateTo('affiliate/update', affiliate)">  <i class="material-icons">edit</i></button>
                                <button mat-flat-button color="primary" (click)='openChangeStatusDialog(affiliate)'>{{(affiliate.enabled ? 'global.disable' : 'global.enable' )| translate }}</button>
                                <button mat-flat-button color="primary" (click)='previousCampaigns(affiliate)'>{{'home.campaign-list' | translate }}</button>
                                <button mat-flat-button color="primary" *ngIf="affiliate.buyAddresses" (click)='previousOrders(affiliate)'>{{'address-order.summary' | translate }}</button>
                                <button mat-flat-button color="primary" *ngIf='currentUser.makeReduction'  (click)='openReductionDialog(affiliate)'>{{'affiliate.buttons.discount' | translate }}</button>
                                
                            </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #emptyAffiliate>
            {{'affiliate.message' | translate}}
        </ng-template>
    </div>
</div>


<ng-template #affiliateStatusModal let-data>
    <h2 matDialogTitle>{{"affiliate.modal.title" | translate}} {{data.username}}</h2>
    <mat-dialog-content>
        <h4>{{"affiliate.modal.message" | translate}} {{(data.enabled ? 'global.disable' : 'global.enable' )| translate | lowercase}}  {{data.username}}</h4>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]='true'>
        {{'global.submit' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="false" color="primary">{{'global.close' | translate}}</button>
    </mat-dialog-actions>
</ng-template>


<ng-template #affiliateReductionModal let-data>
    <h2 matDialogTitle> {{'affiliate.message.reduction-title' | translate}} : {{data.username}} </h2>
    <mat-dialog-content>
    <div class="input-field">
        <i class="material-icons prefix">euro_symbol</i>
        <input class='col s11' type="number" [(ngModel)]="data.reduction" />
        
                
                
        
    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data.reduction" [disabled]="!data.reduction || data.reduction === 0">
        {{'global.submit' | translate}}
    </button>
    <button mat-button [mat-dialog-close]="false" color="primary">{{'global.close' | translate}}</button>
    </mat-dialog-actions>


</ng-template>