import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AffiliateService } from '../../../services/affiliate.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { confirmPasswordValidator, phoneValidator, passwordValidator } from '../../../helpers/validators';
import { DataService } from '../../../helpers/data.service';
import { User } from '../../../models/user';
import { first } from 'rxjs/operators';
import { Affiliate } from '../../../models/affiliate';
import {MatSnackBar} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-affiliate-form',
  templateUrl: './affiliate-form.component.html',
  styleUrls: ['./affiliate-form.component.css']
})
export class AffiliateFormComponent implements OnInit {

  affiliateForm: FormGroup;
  submitted: boolean;
  currentUser: User;
  currentAffilate: Affiliate;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private affiliateService: AffiliateService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.currentUser = this.dataService.state.user;
    this.currentAffilate = this.dataService.state.affiliateToUpdate as Affiliate;


    this.affiliateForm = this.formBuilder.group({
      username: new FormControl(this.currentAffilate ? this.currentAffilate.username : '', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      email: new FormControl(this.currentAffilate ? this.currentAffilate.email : '', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      company: new FormControl(this.currentAffilate ? this.currentAffilate.company : '', Validators.required),
      companyAddress: new FormControl(this.currentAffilate ? this.currentAffilate.companyAddress : '', Validators.required),
      siret: new FormControl(this.currentAffilate ? this.currentAffilate.siret : '', Validators.required),
      phone: new FormControl(this.currentAffilate ? this.currentAffilate.phone : '', Validators.compose([
        Validators.required,
        phoneValidator
      ]))
    }, { validator: confirmPasswordValidator});

      if(this.currentAffilate){
        this.affiliateForm.controls.username.disable();
        this.affiliateForm.controls.password.clearValidators();
        this.affiliateForm.controls.confirmPassword.clearValidators();
        this.affiliateForm.controls.password.updateValueAndValidity();

      }


  }

  get f(){
    return this.affiliateForm.controls;
  }

  onStrengthChanged(value){
    if(value === 100)
      {
        this.affiliateForm.controls.password.setErrors(null);
        this.cd.detectChanges();
        return;
      }


     this.affiliateForm.controls.password.setErrors({'incorrect': true});
     this.cd.detectChanges();

  }


  onSubmit(){
    this.submitted = true;
    if(this.affiliateForm.valid)
    {
      let aff = this.affiliateForm.getRawValue();
      delete aff['confirmPassword'];
      aff['headoffice'] = this.currentUser.id;
      if(this.router.url === '/affiliate/create'){
        this.affiliateService.checkUsername(this.affiliateForm.controls.username.value)
        .pipe(first())
        .subscribe(
          data => {
            if(data)
            return this.affiliateForm.controls.username.setErrors({'isUniqueUsername': true})
            else {

              this.affiliateService.addAffiliate(aff)
              .pipe(first())
              .subscribe(
                () => {
                  this.router.navigate(['/affiliate'])
                  this.snackBar.open(`${aff.username} ${this.translate.instant('affiliate.success.created')}`, this.translate.instant('global.close'), {
                    duration: 2000
                  })
                },
                error => console.error(error)
                )
              }
              error => console.error(error);
            })
      }
      else if(this.router.url === '/affiliate/update'){
        aff['id'] = this.currentAffilate.id;
        this.affiliateService.updateAffiliate(aff)
        .subscribe(
          data => {
              this.snackBar.open(`${aff.username} ${this.translate.instant('affiliate.success.updated')}`, this.translate.instant('global.close'), {
                duration: 2000
            })
              this.router.navigate(['/affiliate'])
            },
          error => console.error(error)
        )
      }
    }
  }


}
