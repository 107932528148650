import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }


  changePassword(userId, oldPassword, newPassword){
    let passWd = {id: userId, oldPasswd: oldPassword, newPasswd: newPassword};
    return this.http.put(`${environment.baseUrl}/user/passwd`, passWd);
  }
}
