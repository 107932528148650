import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { appRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { LoginComponent } from './pages/login/login.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JwtInterceptorService } from './helpers/jwt-interceptor.service';
import { ErrorInterceptorService } from './helpers/error-interceptor.service';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from './material.module';
import { PitchComponent } from './components/pitch/pitch.component';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from 'src/environments/environment';
import { ProspectionComponent } from './pages/prospection/prospection.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DataService } from './helpers/data.service';
import { FlyerComponent } from './pages/flyer/flyer.component';
import { GeoStrategyComponent } from './pages/geo-strategy/geo-strategy.component';
import { ELMComponent } from './pages/elm/elm.component';
import { FilterSliderComponent } from './components/filter-slider/filter-slider.component';
import { GeoStrategyFlatTreeComponent } from './components/geo-strategy-flat-tree/geo-strategy-flat-tree.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { RecapitulateComponent } from './pages/recapitulate/recapitulate.component';
import { AddressOrderComponent } from './pages/address-order/address-order.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxPayPalModule } from 'ngx-paypal';
import { CampaignNameDialogComponent } from './components/campaign-name-dialog/campaign-name-dialog.component';
import { AddressOrderInfoDialogComponent } from './components/address-order-info-dialog/address-order-info-dialog.component';
import { AffiliateFormComponent } from './pages/affiliate/affiliate-form/affiliate-form.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CampaignListComponent } from './pages/campaign-list/campaign-list.component';
import { AddressOrderListComponent } from './pages/address-order-list/address-order-list.component';
import { HeadofficeAdministrationComponent } from './pages/headoffice-administration/headoffice-administration.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { RemoveAddressesComponent } from './pages/remove-addresses/remove-addresses.component';
import { RadiusPipe } from './pipes/radius.pipe';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ContactComponent,
    HomeComponent,
    HeaderComponent,
    PitchComponent,
    AffiliateComponent,
    ProspectionComponent,
    AutocompleteComponent,
    FlyerComponent,
    GeoStrategyComponent,
    ELMComponent,
    FilterSliderComponent,
    GeoStrategyFlatTreeComponent,
    FileViewerComponent,
    RecapitulateComponent,
    AddressOrderComponent,
    ChangePasswordComponent,
    CampaignNameDialogComponent,
    AddressOrderInfoDialogComponent,
    AffiliateFormComponent,
    BreadcrumbComponent,
    CampaignListComponent,
    AddressOrderListComponent,
    HeadofficeAdministrationComponent,
    RemoveAddressesComponent,
    RadiusPipe,
  ],
  imports: [
    BrowserModule,
    appRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    PdfViewerModule,
    MatPasswordStrengthModule.forRoot(),
    MatProgressBarModule,
    MatTabsModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.token
    }),
    ReactiveFormsModule,
    MaterialModule,
    Ng5SliderModule,
    NgxPayPalModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    DataService
  ],
  entryComponents: [
    FileViewerComponent,
    CampaignNameDialogComponent,
    AddressOrderInfoDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
