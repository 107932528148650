import { FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { phoneValidator } from 'src/app/helpers/validators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  loading: boolean;
  submitted: boolean;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([ Validators.required, Validators.email ])),
      phone: new FormControl('', phoneValidator),
      message: new FormControl('', Validators.required)
    })
  }

  onSubmit(){
    this.submitted = true;
    if(this.contactForm.valid){
      const data = this.contactForm.getRawValue();
      this.loading = true;
      this.http.post(`${environment.baseUrl}/contact/`, data)
        .pipe(first())
        .subscribe(() => {
          this.snackBar.open(this.translate.instant("contact.successMessage"), null, {
            duration: 5000,
          });
          this.router.navigate(["/"])
        }, err => {
          console.error(err);
          this.loading = false;
        });
    }
  }

}


