import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../helpers/data.service';
import { User } from 'src/app/models/user';
import { CampaignService } from '../../services/campaign.service';
import { Campaign } from '../../models/campaign';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CampaignNameDialogComponent } from 'src/app/components/campaign-name-dialog/campaign-name-dialog.component';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.css']
})
export class CampaignListComponent implements OnInit {


  currentUser: User;
  campaigns: Campaign[] = [];
  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private campaignService: CampaignService,
    private route:ActivatedRoute,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private router: Router
  ) { }

  ngOnInit() {    
    setTimeout(() => this.dataService.enableLoader(true));
    this.currentUser = this.dataService.state.user;
    
      let userId
      if(this.currentUser.type == "Headoffice")
        userId = Object.keys(this.route.snapshot.queryParams).length > 0 ? this.route.snapshot.queryParams.id : this.currentUser.id;
      else 
        userId  = this.currentUser.id;
    
    this.campaignService
      .campaignsByUser(userId)
      .pipe(first())
      .subscribe(
        campaigns => {
          this.campaigns = campaigns;
          this.dataService.enableLoader(false);
        },
        error => console.error(error)
      )
  }

  handleNewCampaignData = data => {
    this.dataService.setCampaign(data);
    if(data.selected_zones.length > 0)
      this.router.navigate(['/geo-strategy'])
    else
      this.router.navigate(['/prospection'])
  }

  replay(campaign: Campaign){
    if(campaign.status === 'initialized' || campaign.status === 'flyerStep'){
      throw Error("Cannot replay a campaign that hasn't been ordered");
    }
    this.campaignService
      .replayCampaign(campaign)
      .pipe(first())
      .subscribe(
        this.handleNewCampaignData,
        error => console.error(error)
      )
  }

  complete(campaign: Campaign){
    if(campaign.status !== 'initialized' && campaign.status !== 'flyerStep'){
      throw Error("Cannot complete a campaign that has already been ordered");
    }
    this.campaignService
      .completeCampaign(campaign)
      .pipe(first())
      .subscribe(
        this.handleNewCampaignData,
        error => console.error(error)
      )
  }

}
