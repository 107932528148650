<div class="container">
    <div class="card">
        <div class="card-content black-text">
            <div class="row">
                <blockquote class='info' (click)='showInfo()'>
                    {{'remove-addresses.rules.format' | translate}} <i class="material-icons  right">info_outline</i>
                </blockquote>
                <div class="file-field input-field">
                    <div class="btn rounded-button">
                        <span>{{'remove-addresses.input-name' | translate}}</span>   
                        <input type="file" name='flyer' accept=".csv" (change)='uploadFile($event.target)'>
                    </div>
                    <div class="file-path-wrapper">
                        <input class="file-path validate white-text" type="text" >
                    </div>
                    <div *ngIf='error' class="invalid-feedback red-text">
                        {{ error | translate }} {{errorLines ? errorLines : ''}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ng-template #infoTemplate>
    <h2 matDialogTitle>{{'remove-addresses.dialog-title' | translate}}</h2>
    <mat-dialog-content>
            <div className="margin-top">
                    {{"remove-addresses.rules.format" | translate }}
                </div>
                <div className="margin-top">
                    <b className="row">
                            {{"remove-addresses.rules.others" | translate }}
                    </b>
                    <table class='table striped'>
                        <tr>
                            <td>{{"remove-addresses.rules.num" | translate }}</td>
                            <td>{{"remove-addresses.rules.rep" | translate }}</td>
                            <td>{{"remove-addresses.rules.road" | translate }}</td>
                            <td>{{"remove-addresses.rules.cp" | translate }}</td>
                            <td>{{"remove-addresses.rules.town" | translate }}</td>
                        </tr>
                    </table>
                </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="false" color="primary">{{'global.close' | translate}}</button>
    </mat-dialog-actions>
</ng-template>