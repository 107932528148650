<div class="section"></div>
<pitch></pitch>
<div class="section"></div>
<div class="section"></div>
<div class="container">
  <div class="row">
    <a
      routerLink="/affiliate"
      mat-raised-button
      class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
      >{{ "home.manage-affiliate" | translate }}</a
    >
    <a
      routerLink="/campaigns"
      mat-raised-button
      class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
      >{{ "home.campaign-list" | translate }}</a
    >
    <a
      *ngIf="currentUser.buyAddresses"
      routerLink="/address-orders"
      mat-raised-button
      class="col s10 offset-s1 m8 offset-m2 rounded-button push-down"
      >{{ "address-order-list.summary" | translate }}</a
    >
  </div>
</div>
