import { Component, OnInit, NgZone } from '@angular/core';
import * as MapboxGL from "mapbox-gl";
import { environment } from "../../../environments/environment";
import { Options } from "ng5-slider";
import { LngLatLike } from "mapbox-gl";
import geoJsonExtent from "@mapbox/geojson-extent";
import * as turf from "@turf/circle";
import { TranslateService } from "@ngx-translate/core";
import { GeoLocation } from "src/app/models/geo-location";
import { CampaignService } from "../../services/campaign.service";
import { AddressesOrderService } from "../../services/addressesOrder.service";
import { first } from "rxjs/operators";
import { DataService } from "../../helpers/data.service";
import { Campaign } from "../../models/campaign";
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { CampaignStatus } from '../../utils/campaign-status';
import { AddressesOrderStatus } from '../../utils/addresses-order-status';
import { CampaignNameDialogComponent } from '../../components/campaign-name-dialog/campaign-name-dialog.component';
import { AddressOrderInfoDialogComponent } from '../../components/address-order-info-dialog/address-order-info-dialog.component';
import { RadiusPipe } from '../../pipes/radius.pipe';
import { AddressOrder } from 'src/app/models/address-order';


@Component({
  selector: "app-prospection",
  templateUrl: "./prospection.component.html",
  styleUrls: ["./prospection.component.css"],
  providers: [RadiusPipe]
})
export class ProspectionComponent implements OnInit {
  map: MapboxGL.Map;
  style: string;
  address: object;
  enableFindButton: boolean = false;
  currentUser: User;
  disableRadius: boolean = true;
  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    tickStep: 10,
    minLimit: 1,
    showTicks: true,
    showTicksValues: true,
    animate: false,
    hidePointerLabels: true,
    disabled: this.disableRadius
  };
  campaign: Campaign;
  priceToShow: number = 0;


  constructor(
    translate: TranslateService,
    private campaignsService: CampaignService,
    private addressesOrderService: AddressesOrderService,
    private dataService: DataService,
    private dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private radiusPipe: RadiusPipe
  ) {}

  ngOnInit() {
    setTimeout(() => this.dataService.enableLoader(true));
    this.style = environment.mapbox.prospection;
    this.currentUser = this.dataService.state.user;
    const campaign = this.dataService.state.campaign;

    if(campaign && campaign.numberOfAddressesArray && campaign.numberOfAddressesArray.length > 0){
      this.campaign = campaign;
      this.getPriceToShow();
    } else if(campaign && campaign.status) {
      // campaign was created in geo-strategy mode or previously saved
      this.campaign = {
        ...campaign,
        numberOfAddressesArray: campaign.numberOfAddressesArray || [],
        address: campaign.address || {
          coordinates: {
            coordinates: [2.213749, 46.227638]
          }
        },
        radius: campaign.radius || 1
      }
    } else {
      this.campaign = {
        radius: 1,
        user: this.currentUser.id,
        address: {
          coordinates: {
            coordinates: [2.213749, 46.227638]
          }
        },
        numberOfAddressesArray: []
      };
    }
  }


  initMap(map: MapboxGL.Map) {
    this.map = map;
    this.map.setCenter(<LngLatLike>this.campaign.address.coordinates.coordinates);

    this.map.addSource("circle_pools", {
      type: "geojson",
      data: turf.default(this.campaign.address.coordinates.coordinates, 1, { units: "kilometers" })
    });
    if(this.dataService.state.campaign){
      this.map.addLayer({
        id: "circle_pools",
        type: "fill",
        source: "circle_pools",
        layout: {},
        paint: {
          "fill-color": "#F68A1E",
          "fill-opacity": 0.35,
          "fill-outline-color": "#DA7A1A",
        }
      });
      if(this.campaign && this.campaign.status){
        this.radiusChange();
      }
    }
    this.dataService.enableLoader(false);
  }


  getPriceToShow(){
    let price:number, flyerPrice:number = 0;
    this.priceToShow = 0;

    this.currentUser.pricing.ranges.map(range => {
      if(this.campaign.numberOfAddresses >= range.range){
        price = range.priceHO;
      }
    });
    this.priceToShow+= Number(price);

    if(this.currentUser.flyerStep){
      let defaultPostage = this.currentUser.postage.filter(post => post.default === true)[0];
      defaultPostage.prices.map(pric => {
        if(this.campaign.numberOfAddresses >= pric.range){
          flyerPrice = pric.price;
        }
      })
    }
    this.priceToShow+= Number(flyerPrice);
  }




  radiusChange() {

    if(this.campaign.numberOfAddressesArray.length > 0){
      let numberOfAddresses = this.campaign.numberOfAddressesArray.filter(obj => obj[this.campaign.radius])
      if(numberOfAddresses.length > 0){
        this.campaign.numberOfAddresses = numberOfAddresses[0][this.campaign.radius];
        this.getPriceToShow();
        this.disableRadius = false;
        this.sliderOptions = Object.assign({}, this.sliderOptions, {disabled: this.disableRadius});
      }
      else {
        let geoLocation: GeoLocation = {
          type: "Point",
          coordinates: this.campaign.address.coordinates.coordinates
        };

        this.campaignsService
          .countByGeoloc(geoLocation, this.campaign.radius, this.currentUser.id)
          .pipe(first())
          .subscribe(
            (data: object) => {
              this.campaign.numberOfAddressesArray.push(data);
              this.campaign.numberOfAddressesArray = [...this.campaign.numberOfAddressesArray];
              this.campaign.numberOfAddresses = this.radiusPipe.transform(this.campaign.numberOfAddressesArray, this.campaign.radius);
              this.getPriceToShow();
            },
            error => {
              console.error(error);
            }
          )
      }
    }
    else {
      let geoLocation: GeoLocation = {
        type: "Point",
        coordinates: this.campaign.address.coordinates.coordinates
      };

      this.campaignsService
        .countByGeoloc(geoLocation, this.campaign.radius, this.currentUser.id)
        .pipe(first())
        .subscribe(
          (data: object) => {
            this.campaign.numberOfAddressesArray.push(data);
            this.campaign.numberOfAddressesArray = [...this.campaign.numberOfAddressesArray];
            this.campaign.numberOfAddresses = this.radiusPipe.transform(this.campaign.numberOfAddressesArray, this.campaign.radius);
            this.getPriceToShow();
            this.disableRadius = false;
            this.sliderOptions = Object.assign({}, this.sliderOptions, {disabled: this.disableRadius});
          },
          error => {
            console.error(error);
          }
        )
    }






    // redrawing the map
    let newRadius = turf.default(this.campaign.address.coordinates.coordinates, this.campaign.radius, {
      units: "kilometers"
    });
    (this.map.getSource("circle_pools") as MapboxGL.GeoJSONSource).setData(
      newRadius
    );
    let bounds = geoJsonExtent(newRadius);
    this.map.fitBounds(bounds, {
      padding: 20
    });
  }

  setAddress(address) {
    this.address = address;
    if (address.geometry) {
      let location = address.geometry.location;
      this.enableFindButton = true;
      this.campaign.address = {
        coordinates: { type: "Point", coordinates: [location.lng(), location.lat()] },
        full_address: this.address["formatted_address"]
      };
    }
  }



  countAddresses() {
    if (this.address && this.address["geometry"]) {
      if (this.map.getLayer("circle_pools"))
        this.map.removeLayer("circle_pools");

      this.dataService.enableLoader(true);
      let geoLocation: GeoLocation = {
        type: "Point",
        coordinates: this.campaign.address.coordinates.coordinates
      };

      this.campaignsService
        .countByGeoloc(geoLocation, this.campaign.radius, this.currentUser.id)
        .pipe(first())
        .subscribe(
          (data: object) => {
            this.campaign.numberOfAddressesArray = [data];
            this.map.setCenter(<LngLatLike>this.campaign.address.coordinates.coordinates);
            this.radiusChange();
            this.map.addLayer({
              id: "circle_pools",
              type: "fill",
              source: "circle_pools",
              layout: {},
              paint: {
                "fill-color": "#F68A1E",
                "fill-opacity": 0.35,
                "fill-outline-color": "#DA7A1A",
              }
            });
            this.disableRadius = false;
            this.sliderOptions = Object.assign({}, this.sliderOptions, {disabled: this.disableRadius});
            this.dataService.enableLoader(false);
          },
          error => {
            console.error(error);
            this.dataService.enableLoader(false);
          }
        );
      this.enableFindButton = false;
    }
  }
  
  saveAsCampaign = async () => {
    const data: any = {};
    if(this.campaign.name) {
      data.name = this.campaign.name;
    }
    const dialogRef = this.dialog.open(CampaignNameDialogComponent, { data });
    const name = await dialogRef.afterClosed().toPromise();
    if(!name) return;
    await this.saveCampaign(name);
  }

  saveCampaign = async (campaignName): Promise<any> => {
    this.dataService.enableLoader(true);
    this.campaign.name = campaignName;
    this.campaign.status = CampaignStatus.init;
    const { numberOfAddressesArray, ...camp} = this.campaign;
    let request = null;
    if(!this.campaign.id){
      request = this.campaignsService.init(camp);
    } else {
      camp.id = this.campaign.id;
      request = this.campaignsService.update(camp);
    }
    try{
      const data = await request.toPromise()
      this.campaign.id = data['_id'];
      this.campaign.price = data['price'];
      this.dataService.setCampaign(this.campaign);
      this.dataService.enableLoader(false);
    }catch(error){
      console.error(error)
      this.dataService.enableLoader(false);
      throw error;
    }
  }

  saveOrder = async (order: AddressOrder): Promise<any> => {
    this.dataService.enableLoader(true);
    order.status = AddressesOrderStatus.init;
    try{
      const data = await this.addressesOrderService.init(order).toPromise()
      order.id = data['_id'];
      order.price = data['price'];
      this.dataService.setAddressOrder(order);
      this.dataService.enableLoader(false);
    }catch(error){
      console.error(error)
      this.dataService.enableLoader(false);
      throw error;
    }
  }

  orderCampaign = async () => {
    await this.saveAsCampaign();
    const { flyerStep } = this.currentUser;
    this.router.navigate([flyerStep ? '/flyer' : '/recapitulate']);
  }

  getBuyPrice = (nbAddresses: number): number => {
    const range = this.currentUser.buyPricing.ranges
      .slice()
      .reverse()
      .find(_ => nbAddresses >= _.range)
    if(!range) throw Error("Invalid buyPricing");
    return range.priceHO * nbAddresses;
  }

  orderAddresses = async () => {
    const dialogRef = this.dialog.open(AddressOrderInfoDialogComponent);
    const proceed: boolean = await dialogRef.afterClosed().toPromise();
    if(!proceed) return;
    const { address, numberOfAddresses, radius, user, price, order, removed_addresses, selected_zones} = this.campaign;
    const addressOrder: AddressOrder = {
      address, numberOfAddresses, radius, user, price, order, removed_addresses, selected_zones
    }
    await this.saveOrder(addressOrder);
    this.router.navigate(['/order-addresses']);
  }

}
