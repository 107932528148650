
<mat-dialog-content class="mat-typography">         
        <pdf-viewer
        *ngIf='data.file.file.type === "application/pdf"'
        [autoresize]="true"
        [original-size]="false"
        [src]="data.file.url"
        ></pdf-viewer>
        <img [src]="data.file.url" class='responsive-img' *ngIf='data.file.file.type.split("/")[0] === "image"'>
</mat-dialog-content>
