<h2 matDialogTitle>{{'campaign.name' | translate}}</h2>
<mat-dialog-content>
  <div class="input-field">
    <input type="text" [(ngModel)]="data.name" />
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="data.name" [disabled]="!data.name">
    {{'global.submit' | translate}}
  </button>
  <button mat-button [mat-dialog-close]="false" color="primary">{{'global.close' | translate}}</button>
</mat-dialog-actions>
