import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private snackBar: MatSnackBar, private translate: TranslateService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
        if ((err.status === 401 || err.status  === 403) && request.url !== `${environment.baseUrl}/user/data`) {
            // auto logout if 401 or 403 response returned from api
            this.authService.logout();
            location.reload(true);
        }
        else if(err.status === 500 || err.status === 502){
            this.authService.logout();
            this.snackBar.open(
              this.translate.instant('global.error-message'),
              this.translate.instant('global.close'),
              { duration: 4000 }
            )
            setTimeout(() => location.reload(true), 4000);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
    }))
}
}
