import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if(this.dataService.state.user) return true;
    const queryParams: any = {};
    if(route.data.allowRedirect) {
      queryParams.returnUrl = state.url;
    }
    this.router.navigate(['/login'], { queryParams });
    return false;
  }
}
