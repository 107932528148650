import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "radius",
})
export class RadiusPipe implements PipeTransform {
  transform(radiuses: object[], radius: number): any {
    const elt = radiuses.find((obj) => obj[radius]);
    if (elt !== null) {
      return elt[radius];
    }
  }
}
