const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover4.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if(elmGenerator.logo){
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61
      }
    })
  }

  slide.addText("Lexique (1/2)", {
    x: 4.18,
    y: 0.64,
    w: 5.32,
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "9BCF63",
  });

  slide.addText(
    [
      {
        text: "Revenu médian",
        options: { fontSize: 12, breakLine: true },
      },
      {
        text: `Il correspond à la médiane du revenu disponible par unité de consommation (UC), également appelé "niveau de vie". Il est le revenu disponible par "équivalent adulte", calculé en rapportant le revenu disponible du ménage au nombre d'unités de consommation qui le composent. Toutes les personnes rattachées au même ménage fiscal ont le même revenu disponible par UC (ou niveau de vie).`,
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      { text: "CSP+", options: { fontSize: 12, breakLine: true } },
      {
        text: "Est intégré toute personne inclue dans les catégories Artisans, Comm., Chefs entr.,Cadres, Prof. intel. Sup., & Prof. intermédiaires.",
        options: { breakLine: true, paraSpaceAfter: 10 },
      },
      {
        text: "Ratio CSP+",
        options: { fontSize: 12, breakLine: true },
      },
      {
        text: "Le ratio de CSP+ est le rapport entre le nombre de personnes intégrées au sein de la catégorie CSP+ et le nombre de personnes de plus de 15 ans.",
        options: { breakLine: true },
      },
    ],
    {
      x: 4.18,
      y: 1.39,
      w: 5.69,
      h: 3.26,
      fontFace: "Pontano Sans",
      fontSize: 10,
      color: "484F56",
      valign: "top",
      paraSpaceAfter: 3,
    }
  );
};

export default addSlide;
