import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from './helpers/data.service';
import { environment } from '../environments/environment';


declare let gtag:Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  private jwtHelper = new JwtHelperService();
  gtagId;
  ngOnInit() {
    this.gtagId = environment.gtagId;
  }


  /**
   * On application initlized, setting default language,
   * On event emmited by router (changing route), checking if user has a valid token else disconnect him.
   * @param translate
   * @param router
   * @param authService
   * @param snackBar
   * @param dataService
   */
  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    public dataService: DataService
  )
  {
    translate.setDefaultLang('fr');
    this.translate.use('fr');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.dataService.enableLoader(true);
      } else if (event instanceof NavigationEnd) {
        this.dataService.enableLoader(false);
        if(!environment.hmr) {
          if(this.dataService.state.user) {
            gtag('set', {'user_id': this.dataService.state.user.username});
          }
          gtag('config', this.gtagId,{'page_path' : event.url});
        }
      }
    })
  }
}
