const dimensionsFromWidth = (width, ratio) => ({
  w: width,
  h: width / ratio,
});

const addSlide = (elmGenerator) => {
  const slide = elmGenerator.pres.addSlide("SLIDE_WITH_NUMBER");

  slide.addImage({
    path: "assets/images/elm/cover4.jpg",
    w: "100%",
    h: "100%",
  });

  slide.addImage({
    path: "assets/images/elm/logo_poolspot.png",
    x: 4.2,
    y: 4.92,
    w: 1.72,
    h: 0.61,
  });

  if(elmGenerator.logo){
    slide.addImage({
      data: elmGenerator.logo,
      x: 9,
      y: 4.92,
      w: elmGenerator.logoDimensions.width / 100,
      h: elmGenerator.logoDimensions.height / 100,
      sizing: {
        type: "contain",
        w: 1,
        h: 0.61
      }
    })
  }

  slide.addText("Zone de l'étude", {
    x: 4.18,
    y: 0.14,
    w: 5.32,
    h: 0.76,
    fontFace: "Dosis",
    fontSize: 36,
    color: "9BCF63",
  });

  slide.addText("L'étude est réalisée sur le territoire défini ci-dessous.", {
    x: 4.18,
    y: 0.82,
    w: 5.69,
    h: 0.43,
    fontFace: "Pontano Sans",
    fontSize: 12,
    color: "484F56",
    valign: "top",
  });

  slide.addImage({
    data: elmGenerator.elmData.depImg,
    ...dimensionsFromWidth(5, 4 / 3),
    x: 4.18,
    y: 1.1,
  });
};

export default addSlide;
