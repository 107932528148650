import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "../../helpers/data.service";
import { CampaignService } from "../../services/campaign.service";
import { AddressesOrderService } from "../../services/addressesOrder.service";
import { MatSnackBar, MatDialog } from "@angular/material";
import { Campaign } from "../../models/campaign";
import { AddressOrder } from "../../models/address-order";

@Component({
  selector: "app-remove-addresses",
  templateUrl: "./remove-addresses.component.html",
  styleUrls: ["./remove-addresses.component.css"],
})
export class RemoveAddressesComponent implements OnInit {
  invalidFile: boolean;
  error: string;
  errorLines: string;
  campaign: Campaign;
  addressOrder: AddressOrder;

  @ViewChild("infoTemplate", { static: false }) infoTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dataService: DataService,
    private campaignService: CampaignService,
    private addressesOrderService: AddressesOrderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.campaign = this.dataService.state.campaign;
    this.addressOrder = this.dataService.state.addressOrder;
  }

  uploadFile(input: HTMLInputElement) {
    delete this.error;
    const files = input.files;
    if (files && files.length) {
      const file = files[0];
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = this.onFileLoad.bind(this);
    }
  }

  onFileLoad(fileLoadedEvent): void {
    delete this.errorLines;
    const csvSeparator = ";";
    const txt = fileLoadedEvent.target.result;
    const csv = [];
    const lines = txt.split("\n").filter((_) => _ !== "");
    lines.forEach((element) => {
      const cols: string[] = element
        .split(csvSeparator)
        .map((col) => col.toLowerCase());
      csv.push(cols);
    });
    const filtredLines = csv.filter((line) => line.length < 5);
    const { queryParams } = this.route.snapshot;
    if (filtredLines.length > 0) {
      this.errorLines = filtredLines
        .map((line) => csv.indexOf(line) + 1)
        .toString();
      console.log(filtredLines);
      this.error = "remove-addresses.rules.lines-error";
    } else if(queryParams.target === "address-order") {
      this.addressesOrderService.removeAddresses(this.addressOrder, csv).subscribe(
        (data) => {
          this.snackBar.open(`${data["removed"]} removed addresse`, "OK", {
            duration: 5000,
          });
          // we have to delete removed_addresses, or else it will override backend value on next update;
          delete this.addressOrder.removed_addresses;
          this.addressOrder.price = data["price"];
          this.addressOrder.numberOfAddresses = data["numberOfAddresses"];
          this.dataService.setAddressOrder(this.addressOrder);
          this.router.navigate(["/order-addresses"]);
        },
        (err) => console.error(err)
      );
    }else{
      this.campaignService.removeAddresses(this.campaign, csv).subscribe(
        (data) => {
          this.snackBar.open(`${data["removed"]} removed addresse`, "OK", {
            duration: 5000,
          });
          // we have to delete removed_addresses, or else it will override backend value on next update;
          delete this.campaign.removed_addresses;
          this.campaign.removedAddresses = data["removed"];
          this.campaign.price = data["price"];
          this.campaign.numberOfAddresses = data["numberOfAddresses"];
          this.dataService.setCampaign(this.campaign);
          this.router.navigate(["/recapitulate"]);
        },
        (err) => console.error(err)
      );
    }
  }

  showInfo() {
    this.dialog.open(this.infoTemplate, { width: "60%" });
  }
}
