<div class='container'>
  <div class="row">
    <div class="card">
      <div class='card-content black-text'>
        <span class="card-title">
          {{ "contact.cardTitle" | translate }}
        </span>
        <p class="">
          {{ "contact.cardSubtitle" | translate }}
        </p><br/>
        <form [formGroup]='contactForm'  (ngSubmit)='onSubmit()'>
          <div class='input-field col s12'>
            <input class='validate' type='text' name='name' id='name' formControlName="name" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.name.errors }" required autocomplete="name"/>
            <label class='active' for='name'>{{'contact.form.input.name' | translate}}&nbsp;*</label>
            <div *ngIf="submitted && contactForm.controls.name.errors" class="invalid-feedback red-text">
              <div *ngIf="contactForm.controls.name.errors.required ">{{'contact.form.message.name' | translate}}</div>
            </div>
          </div>
          <div class='input-field col s12'>
            <input class='validate' type='email' name='email' id='email' formControlName="email" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.email.errors }" required/>
            <label  class='active' for='email'>{{'contact.form.input.email' | translate}}&nbsp;*</label>

            <div *ngIf="submitted && contactForm.controls.email.errors" class="invalid-feedback red-text">
              <div *ngIf="contactForm.controls.email.errors.required ">{{'contact.form.message.email' | translate}}</div>
              <div *ngIf="contactForm.controls.email.errors.email">{{'contact.form.message.valid-email' | translate}}</div>
            </div>
          </div>
          <div class='input-field col s12'>
            <input class='validate' type='text' name='phone' id='phone' formControlName="phone" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.phone.errors }"/>
            <label class='active' for='phone'>{{'contact.form.input.phone' | translate}}</label>
            <div *ngIf="submitted && contactForm.controls.phone.errors" class="invalid-feedback red-text">
              <div *ngIf="contactForm.controls.phone.errors.pattern ">
                {{'contact.form.message.valid-phone' | translate}}
              </div>
            </div>
          </div>
          <div class='input-field col s12'>
            <textarea class='materialize-textarea validate' type='text' name='message' id='message' formControlName="message" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.message.errors }" required autocomplete="off"></textarea>
            <label class='active' for='message'>{{'contact.form.input.message' | translate}}&nbsp;*</label>
            <div *ngIf="submitted && contactForm.controls.message.errors" class="invalid-feedback red-text">
              <div *ngIf="contactForm.controls.message.errors.required ">{{'contact.form.message.message' | translate}}</div>
            </div>
          </div>
          <div class="row center-align">
            <button type="submit" class='btn rounded-button' [disabled]="loading">{{'global.submit' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
